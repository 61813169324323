//#region IMPORT

import { ENUM_AGENTCERTIFICATE_STATUS, ENUM_AGENTEPKS_STATUS } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class NotificationModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for notification.
		Author: Rizqi Alfiyati
		Created on : Friday, 04 October 2024. 					Updated on : .
		Created by : Rizqi Alfiyati		Updated by : .
		Version : 1.0:1
	*/

	CertificateStatusMessage?: string;
	EPKSStatus?: ENUM_AGENTEPKS_STATUS;
	EPKSStatusMessage?: string;
	CertificateStatus?: ENUM_AGENTCERTIFICATE_STATUS;
	EPKSDate?: string;
	CertificateDate?: string;
}

//#endregion