//#region IMPORT

import { Router } from "@angular/router";
import { PartialObserver } from "rxjs";
import { NumberConstant } from "src/app/constants/number.constant";
import { ConvertToBroadcastURL } from "src/app/functions/pipes/hyperlink.pipe";
import { CustomerModel } from "src/app/models/customer.model";
import { UserModel } from "src/app/models/user.model";
import { SessionService } from "src/app/services/session.service";
import { BasePublicComponent } from "./basepublic.component";

//#endregion


//#region CLASS

export class BaseAuthourizeComponent extends BasePublicComponent
{
	//#region DECLARATION

	public _modelUserSignIn: UserModel;
	public _modelCustomerSignIn: CustomerModel;
	public _pipeConvertToBroadcastURL = new ConvertToBroadcastURL();

	latitude: number = -1.605328;
	longitude: number = 117.451067;
	type = "SATELLITE";
	zoom: number = 15;
	minzoom: number = 2;

	public _numberStepProduct: number = 1;
	public _numberMinProduct: number = 1;
	public _numberTickIntervalProduct: number = 1;
	public _numberMaxProductOtoDaily: number = 27;
	public _numberMaxProductOtoMonthly: number = 11;
	public _numberPixelHeight: number;
	public _numberPixelWidth: number;

	public _numberGeneralPixelWidth: number = 0;

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);

		this._modelUserSignIn = new UserModel();
		this._modelCustomerSignIn = new CustomerModel();

		const observerPartialModelUserSignIn: PartialObserver<UserModel> =
		{
			next: (modelUser: UserModel) => { this._modelUserSignIn = modelUser; },
			error: () => { },
			complete: () => { }
		};

		this._serviceSession._modelUserSignIn.subscribe(observerPartialModelUserSignIn);

		this._numberPixelHeight = 0;
		this._numberPixelWidth = 0;
	}

	//#endregion


	//#region FUNCTION

	protected signOut(): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();

		this._router.navigate([""]).then(() =>
		{
			window.location.reload();
		}).catch();
	}

	protected signOutPublic(): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();
		this._serviceSession.clearSessionQuery();
		window.location.reload();
	}

	protected setViewFromTop(): void
	{
		let top = document.getElementById("divMainDashboard");
		if (top !== null)
		{
			top.scrollIntoView();
			top = null;
		}
	}

	protected setViewFromTopContent(): void
	{
		let top = document.getElementById("sectionPackageProduct");
		if (top !== null)
		{
			top.scrollIntoView();
			top = null;
		}
	}

	protected signOutDownload(stringURL: string): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();
		this._serviceSession.clearSessionQuery();

		this._router.navigate(["signin", stringURL]);
	}

	protected signIn(): void
	{
		this._router.navigate(["signin"]);
	}

	//#endregion


	//#region CHECK

	protected checkURL(stringURLPath: string): boolean
	{
		return this._router.url.indexOf(stringURLPath) > -1;
	}

	protected checkMinimumPixelForPhotoHeader(): boolean
	{
		if(window.innerWidth <= NumberConstant.NUMBER_VALUE_MINIMIZE_NAVIGATION_PIXEL)
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	//#endregion
}

//#endregion