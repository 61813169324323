//#region IMPORT

import { ENUM_SORT_TYPE } from "src/app/constants/enum.constant";
import { GeneralModel } from "./general.model";

//#endregion


//#region CLASS

export class TableModel extends GeneralModel
{
	/*
		Attribute - START
		Description : Attribute for Table model.
		1. This will be container model for pagination, the content will be filled in Search and Result.
		2. Search will be filled with the data model for searching.
		3. Result will be filled with the array of data model after searching.
		Author: Andri Septiawan.
		Created on : Tuesday, 29 December 2020.		        Updated on : Tuesday, 29 December 2020.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

	RowPerPage?: number;
	PageTotal?: number;
	Page?: number;
	Search?: string;
	SortBy?: string;
	SortType?: ENUM_SORT_TYPE;
	Result?: string;

	/* Attribute - END */


	//#region CONSTRUCTOR

	constructor()
	{
		super();

		this.RowPerPage = 5;
		this.Page = 1;
	}

	//#endregion


	//#region CHECK

	checkResult(): boolean
	{
		if (this.Result == null || this.Result === undefined || this.Result === "")
		{
			return false;
		}
		else
		{
			return true;
		}
	}

	//#endregion


	//#region PAGING

	selectDataForPaging(paging: (numberIndexFromResult: number, numberIndexToResult: number) => void ): void
	{
		if (this.RowPerPage != null && this.RowPerPage !== undefined && this.RowPerPage > 1)
		{
			if (this.Page != null && this.Page !== undefined && this.Page >= 1)
			{
				const numberIndexTo: number = this.Page * this.RowPerPage;
				const numberIndexFrom: number = (numberIndexTo - this.RowPerPage);
				paging(numberIndexFrom, numberIndexTo);
			}
			else
			{

			}
		}
	}

	//#endregion


	//#region SETTER

	setDataNotFound(): void
	{
		this.Page = 1;
		this.PageTotal = 1;
		this.Result = undefined;
	}

	//endregion
}

//#endregion