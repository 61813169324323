//#region IMPORT

import { Component, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { ENUM_NAVIGATIONMENU_BRINS, ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { WebAddressConstant } from "src/app/constants/webaddress.constant";
import { UserSessionModel } from "src/app/models/usersession.model";
import { SessionService } from "src/app/services/session.service";
import { CustomerService } from "src/app/services/customer.service";
import { UserModel } from "src/app/models/user.model";
import { NumberConstant } from "src/app/constants/number.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { ResponseModel } from "src/app/models/response.model";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";
import { UserAgentModel } from "./../../models/useragent.model";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-home",
		templateUrl: "./home.component.html",
		styleUrls: ["./home.component.sass"]
	}
)

//#endregion


//#region CLASS

export class HomeComponent extends BaseAuthourizeComponent
{

	//#region DECLARATION

	public _enumNavigationMenu= ENUM_NAVIGATIONMENU_BRINS;

	public _booleanMenuMaximize: boolean;
	public _booleanMenuBarMinimize: boolean;
	public _booleanChatMelisa: boolean;
	public _booleanNavigationItem: boolean;
	public _booleanNavigationItem2: boolean;
	public _modelUser: UserModel;

	public _stringConversation: string;
	public _stringConstant = StringConstant;

	public _numberPixelHeight: number;
	public _numberPixelWidth: number;

	public _arrayStringConversation: Array<string>;

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router, private _serviceCustomer: CustomerService)
	{
		super(serviceSession, router);

		this._booleanMenuMaximize = true;
		this._booleanMenuBarMinimize = false;
		this._booleanChatMelisa = false;
		this._booleanNavigationItem = false;
		this._booleanNavigationItem2 = false;
		this._modelUser = new UserModel();


		this._numberPixelHeight = 0;
		this._numberPixelWidth = 0;

		this._stringConversation = "";

		this._arrayStringConversation = [];

		this.getScreenSize();
	}

	//#endregion


	//#region LISTENER

	@HostListener("window:resize", ["$event"])
	// eslint-disable-next-line no-unused-vars
	getScreenSize(event?): void
	{
		this._numberGeneralPixelWidth = window.innerWidth;
		this._numberPixelHeight = window.innerHeight;
		this._numberPixelWidth = window.innerWidth;

		if(this._numberPixelWidth <= NumberConstant.NUMBER_VALUE_MINIMIZE_NAVIGATION_PIXEL)
		{
			this._booleanMenuMaximize = false;
		}
		else
		{
			this._booleanMenuMaximize = true;
		}
	}

	//#endregion


	//#region NGONINIT

	ngOnInit(): void
	{
		this.getScreenSize();
		if(this._modelUserSignIn.Email !== null && this._modelUserSignIn.Email !== undefined)
		{
			this.callSelectAgentByEmail(this, this._modelUserSignIn.Email);
		}
	}

	//#endregion


	//#region WEB SERVICES

	callSelectAgentByEmail(componentCurrent: HomeComponent, stringEmail: any): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);
		stringEmail = this._modelUserSignIn.Email;

		const modelCheckEmail: UserAgentModel = new UserAgentModel();
		modelCheckEmail.Email = stringEmail;
		let modelResponseValidation: ResponseModel = modelCheckEmail.validateEmail();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponseValidation = modelCheckEmail.validateEmail();
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			this._serviceCustomer.selectAgentByEmail
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._functionUserInterface.updateLoadingProgress();

							const modelCheckEmailResponse: UserAgentModel = new UserAgentModel();
							modelCheckEmailResponse.setModelFromString(modelResponse.Data);

							const modelUserSession = new UserSessionModel();
							modelUserSession.UserToken = modelCheckEmailResponse.Token;

							componentCurrent._modelUserCheck = new UserModel();

							if(modelCheckEmailResponse.RegisterStatus === 2)
							{
								componentCurrent._modelUserCheck.AgentToken = modelCheckEmailResponse.AgentToken;
								componentCurrent._modelUserCheck.AgentType = modelCheckEmailResponse.AgentType;
								componentCurrent._serviceSession.setModelUserCheck(modelCheckEmailResponse);
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {componentCurrent.goToSignInWithClearSession();});
								componentCurrent._router.navigate(["/signup"]);
							}

							// componentCurrent._serviceSession.setUserSession(modelUserSession);
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				}
			}, modelCheckEmail);
		}
	}

	public callSignOut(): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		const componentCurrent: HomeComponent = this;
		const modelUserSession: UserSessionModel | null = this._serviceSession.getUserSession();

		if (modelUserSession != null)
		{
			this._serviceCustomer.signOut
			(
				{
					success(modelResponse): void
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
							{
								componentCurrent.signOut();
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
							}
						});
					},
					fail(modelResponse): void
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSignOut(); });
					},
					signOut(modelResponse): void
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
					}
				}, new UserModel()
			);
		}
		else
		{
			componentCurrent.signOut();
		}
	}

	//#endregion


	//#region FUNCION

	public callServiceSignOut()
	{
		const stringMessage = "Sign In\nApakah Anda ingin keluar dari akun?";
		if (confirm(stringMessage) === true)
		{
			this.callSignOut();
		}
		else
		{
		}
	}


	//#endregion


	//#region NAVIGATION

	public goToAbout(): void
	{
		this.minimizeMenu(false);
		this._router.navigate(["home", "about"]);
	}

	public goToProductList(): void
	{
		this.minimizeMenu(false);
		this._router.navigate(["home", "productlist"]);
	}

	public goToPolicy(): void
	{
		this.minimizeMenu(false);
		this._router.navigate(["home", "policy", "list"]);
	}

	public goToMobileApplication(): void
	{
		this.minimizeMenu(false);
		this._router.navigate(["home", "mobileapplication"]);
	}

	public goToBRINSApi(): void
	{
		this.minimizeMenu(false);
		window.open("https://developers.bri.co.id/en", "_blank");
	}

	public goToPromo(): void
	{
		this.minimizeMenu(false);
		this._router.navigate(["home", "promotion"]);
	}

	public goToContact(): void
	{
		this.minimizeMenu(false);
		window.open(WebAddressConstant.STRING_URL_BRINS_WEB+"/home/about/contactandlocation", "_blank");
	}

	public goToNotification(): void
	{
		this.minimizeMenu(false);
		this._router.navigate(["home", "notification"]);
	}

	public goToHome(): void
	{
		this.minimizeMenu(false);
		this._router.navigate(["/"]);
	}

	public goToMainDomain(): void
	{
		this.minimizeMenu(false);
		window.open(WebAddressConstant.STRING_URL_BRINS_WEB, "_blank");
	}

	public goToPromotion(): void
	{
		this.minimizeMenu(false);
		this._router.navigate(["home", "promotion"]);
	}

	public goToPartner(): void
	{
		this.minimizeMenu(false);
		this._router.navigate(["home", "partner"]);
	}

	public goToSignIn(): void
	{
		this.minimizeMenu(false);
		this._router.navigate(["signin"]);
	}

	//#endregion


	//#region SCROLL

	public scrolled(event: any): void
	{
		const top = document.getElementById("divMainDashboard");

		if (top !== null)
		{
			if(event.srcElement.scrollTop <= 200)
			{
				this._booleanMenuMaximize = true;
			}
			else
			{
				this._booleanMenuMaximize = false;
			}
		}

		this._booleanChatMelisa = false;
	}

	public minimizeMenu(booleanMenu: boolean): void
	{
		this._booleanMenuBarMinimize = booleanMenu;
	}

	//#endregion

	//#region FUNCTION

	public setBooleanChatMelisa(booleanMenu: boolean): void
	{
		this._booleanChatMelisa = booleanMenu;
	}

	public setConvertionArray(): void
	{
		if(this._stringConversation === null || this._stringConversation === undefined || this._stringConversation === "")
		{

		}
		else
		{
			this._arrayStringConversation.push(this._stringConversation);
			this._stringConversation = "";
		}
	}

	public setActiveNavigation(enumAboutBRINS: ENUM_NAVIGATIONMENU_BRINS): boolean
	{
		if(enumAboutBRINS === this._enumNavigationMenu.Home)
		{
			if(this._router.url === "/home")
			{
				return true;
			}
			else
			{
				return false;
			}
		}
		else if(enumAboutBRINS === this._enumNavigationMenu.Product)
		{
			if(this._router.url === "/home/productlist")
			{
				return true;
			}
			else
			{
				return false;
			}
		}
		else if(enumAboutBRINS === this._enumNavigationMenu.Policy)
		{
			if(this._router.url === "/home/policy/list")
			{
				return true;
			}
			else
			{
				return false;
			}
		}
		else if(enumAboutBRINS === this._enumNavigationMenu.MobileApplication)
		{
			if(this._router.url === "/home/mobileapplication")
			{
				return true;
			}
			else
			{
				return false;
			}
		}
		else if(enumAboutBRINS === this._enumNavigationMenu.BRINSAPI)
		{
			if(this._router.url === "/home/brinsupdate" || this._router.url === "/home/brinsupdate/newsfeeds" || this._router.url === "/home/brinsupdate/community" || this._router.url === "/home/brinsupdate/article" || this._router.url === "/home/brinsupdate/testimony" || this._router.url === "/home/brinsupdate/financialliteracy")
			{
				return true;
			}
			else
			{
				return false;
			}
		}
		else if(enumAboutBRINS === this._enumNavigationMenu.Partner)
		{
			if(this._router.url === "/home/partner")
			{
				return true;
			}
			else
			{
				return false;
			}
		}
		else if(enumAboutBRINS === this._enumNavigationMenu.Promo)
		{
			if(this._router.url === "/home/promotion")
			{
				return true;
			}
			else
			{
				return false;
			}
		}
		else if(enumAboutBRINS === this._enumNavigationMenu.Contact)
		{
			if(this._router.url === "/home/contact")
			{
				return true;
			}
			else
			{
				return false;
			}
		}
		else if(enumAboutBRINS === this._enumNavigationMenu.Cart)
		{
			if(this._router.url === "/home/cart")
			{
				return true;
			}
			else
			{
				return false;
			}
		}
		else
		{
			return false;
		}
	}

	//#endregion

}

//#endregion
