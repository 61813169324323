//#region IMPORT

import { AgencyDocumentModel } from "src/app/models/agencydocument.model";
import { ENUM_GENDER_TYPE, ENUM_USERGROUPID, ENUM_IDENTIFICATION_TYPE, ENUM_MARITAL_STATUS, ENUM_REGISTER_TYPE, ENUM_REGISTRATION_STATE, ENUM_RELIGION_TYPE, ENUM_RESPONSE_STATE, ENUM_SALUTATION_TYPE, ENUM_USER_ROLE } from "../constants/enum.constant";
import { RuleConstant } from "../constants/rule.constant";
import { StringConstant } from "../constants/string.constant";
import { UserGroupAccessModel } from "./usergroupaccess.model";
import { ENUM_AGENT_TYPE } from "./../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { BranchModel } from "./branch.model";
import { CustomerModel } from "./customer.model";
import { DivisionModel } from "./division.model";
import { HandshakeModel } from "./handshake.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class UserModel extends BaseModel
{
	//#region ATTRIBUTE

	ID?: number;
	Salutation?: ENUM_SALUTATION_TYPE;
	FirstName?: string;
	MiddleName?: string;
	LastName?: string;
	FullName?: string;
	BirthDate?: Date;
	stringBirthDate?: string;
	BirthPlace?: string;
	Gender?: ENUM_GENDER_TYPE;
	MaritalStatus?: ENUM_MARITAL_STATUS;
	Religion?: ENUM_RELIGION_TYPE;
	NationalityID?: number;
	BranchCode?: string;
	BranchID?: number;

	Address?: string;
	CountryID?: number;
	ProvinceID?: number;
	CityID?: number;
	SubDistrictID?: number;
	VillageID?: number;
	HamletCode?: string;
	NeighbourhoodCode?: string;
	PostalID?: number;
	PostalCode?: string;
	Latitude?: number;
	Longitude?: number;
	NIK?: string;
	TaxNumber?: string;
	ReligionType?: ENUM_RELIGION_TYPE;
	BRIAccountNumber?: string;
	MothersMaidenName?: string;
	CertificateExpirationDate?: Date;
	HasCertificate?: any;
	AgentType?: any;
	AgentToken?: string;
	CompanyName?: string;
	GenderType?: ENUM_GENDER_TYPE;

	Telephone?: string;
	Fax?: string;
	MobilePhone?: string;
	PhoneNumber?: string;
	MobilePhoneNew?: string;
	MobilePhoneToken?: string;
	MobilePhoneState?: ENUM_REGISTRATION_STATE;
	Email?: string;
	EmailNew?: string;
	EmailToken?: string;
	EmailState?: ENUM_REGISTRATION_STATE;
	URLValidation?: string;

	IdentificationNumber?: string;
	IdentificationType?: ENUM_IDENTIFICATION_TYPE;
	IdentificationName?: string;
	IdentificationURL?: string;
	Password?: string;
	PasswordNew?: string;
	PasswordConfirmation?: string;
	Role?: ENUM_USER_ROLE;
	DeviceID?: string;
	Key?: string;

	UserLevelID?: number;
	UserPreferenceID?: number;
	LastLoginOn?: Date;
	FailedAttemptLogin?: number;
	SignUpType?: ENUM_REGISTER_TYPE;
	SignUpID?: string;
	SignUpToken?: string;
	Signnumberype?: ENUM_REGISTER_TYPE;

	modelDivision?: DivisionModel;
	modelBranch?: BranchModel;
	modelHandshake?: HandshakeModel;
	modelCustomer?: CustomerModel;
	modelAgent?: AgencyDocumentModel;
	Culture?: string;
	Token?: string;

	search?: string;
	DivisionID?: number;
	ProfileID?: string;
	CustomerCoreID?: string;

	//#endregion

	constructor()
	{
		super();
		this.modelDivision = new DivisionModel();
		this.modelBranch = new BranchModel();
		this.setModelDivison();
		this.setCustomerCoreID();
	}

	setModelDivison(): void
	{
		const stringModelDivision: string = JSON.stringify(this.modelDivision);
		this.modelDivision = new DivisionModel();
		this.modelDivision.setModelFromString(stringModelDivision);
	}

	setCustomerCoreID(): void
	{
		this.CustomerCoreID = this.ProfileID;
	}

	//#region GETTER

	getName(): string
	{
		if (this.FirstName == null || this.FirstName === undefined || this.FirstName === "")
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
		else
		{
			let stringName: string = this.FirstName;

			if (this.MiddleName == null || this.MiddleName === undefined || this.MiddleName === "")
			{

			}
			else
			{
				stringName += StringConstant.STRING_CHARACTER_DASH + this.MiddleName;
			}

			if (this.LastName == null || this.LastName === undefined || this.LastName === "")
			{

			}
			else
			{
				stringName += StringConstant.STRING_CHARACTER_DASH + this.LastName;
			}

			return stringName;
		}
	}

	getNameInitial(): string
	{
		const arrayName: Array<string> = this.getName().split(StringConstant.STRING_CHARACTER_DASH);

		if (arrayName != null && arrayName !== undefined)
		{
			if (arrayName.length >= 2)
			{
				return arrayName[0].substring(0, 1) + arrayName[arrayName.length - 1].substring(0, 1);
			}
			else
			{
				return arrayName[0].substring(0, 2);
			}
		}
		else
		{
			return "";
		}
	}

	getGenderTypeName(): string
	{
		if (this.Gender != null && this.Gender !== undefined)
		{
			if (this.Gender === ENUM_GENDER_TYPE.Male)
			{
				return "Laki-Laki";
			}
			else if (this.Gender === ENUM_GENDER_TYPE.Female)
			{
				return "Perempuan";
			}
			else
			{
				return StringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
	}

	getReligionTypeName(): string
	{
		if (this.Religion != null && this.Religion !== undefined)
		{
			if (this.Religion === ENUM_RELIGION_TYPE.Islam)
			{
				return "Islam";
			}
			else if (this.Religion === ENUM_RELIGION_TYPE.Christianity)
			{
				return "Kristen";
			}
			else if (this.Religion === ENUM_RELIGION_TYPE.Catholicism)
			{
				return "Katolik";
			}
			else if (this.Religion === ENUM_RELIGION_TYPE.Buddhism)
			{
				return "Buddha";
			}
			else if (this.Religion === ENUM_RELIGION_TYPE.Hinduism)
			{
				return "Hindu";
			}
			else if (this.Religion === ENUM_RELIGION_TYPE.Confucianism)
			{
				return "Konghucu";
			}
			else
			{
				return StringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
	}

	getMaritalTypeName(): string
	{
		if (this.MaritalStatus != null && this.MaritalStatus !== undefined)
		{
			if (this.MaritalStatus === ENUM_MARITAL_STATUS.Single)
			{
				return "Lajang";
			}
			else if (this.MaritalStatus === ENUM_MARITAL_STATUS.Married)
			{
				return "Menikah";
			}
			else if (this.MaritalStatus === ENUM_MARITAL_STATUS.Divorce)
			{
				return "Cerai Hidup";
			}
			else if (this.MaritalStatus === ENUM_MARITAL_STATUS.Widow)
			{
				return "Cerai Mati";
			}
			else
			{
				return StringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
	}

	getAgentTypeName(): string
	{
		if (this.modelAgent?.AgentType != null && this.modelAgent?.AgentType !== undefined)
		{
			if (this.modelAgent?.AgentType === ENUM_AGENT_TYPE.Personal)
			{
				return "Individu";
			}
			else if (this.modelAgent?.AgentType === ENUM_AGENT_TYPE.Business)
			{
				return "Badan Usaha";
			}
			else
			{
				return StringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
	}

	//#endregion


	//#region VALIDATION

	validateEmail(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("User");

		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAILWITHOUTEMOJI);

		if (this.Email == null || this.Email === undefined || this.Email === "")
		{
			modelResponse.MessageContent = "Email tidak boleh kosong";
		}
		else if (regularExpression.test(this.Email))
		{
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{
			modelResponse.MessageContent = "Masukkan email yang valid.";
		}

		return modelResponse;
	}

	validateEmailToken(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("User");

		if (this.EmailToken == null || this.EmailToken === undefined || this.EmailToken === "")
		{
			modelResponse.MessageContent = "OTP Email can't be empty.";
		}
		else if (this.EmailToken.length !== RuleConstant.NUMBER_EMAILTOKEN_LENGTH)
		{
			modelResponse.MessageContent = "OTP Email should be " + RuleConstant.NUMBER_EMAILTOKEN_LENGTH + " characters length.";
		}
		else
		{
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}


	validateFormUser(): ResponseModel
	{
		const regExNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_NUMERIC);
		// const regExAlphabet: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABET);
		const regExAlphaNumericForAddress: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERICFORADDRESS);

		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("User");

		this.validateEmail();

		if (this.FullName == null || this.FullName === undefined || this.FullName === "")
		{
			modelResponse.MessageContent = "Nama tidak boleh kosong.";
		}
		// else if (this.MiddleName !== undefined && (!regExAlphabet.test(this.MiddleName)))
		// {
		// 	modelResponse.MessageContent = "Format input Nama Tengah mengandung karakter yang tidak diizinkan.";
		// 	this.MiddleName = undefined;
		// }
		// else if (this.LastName !== undefined && (!regExAlphabet.test(this.LastName)))
		// {
		// 	modelResponse.MessageContent = "Format input Nama Belakang mengandung karakter yang tidak diizinkan.";
		// 	this.LastName = undefined;
		// }
		else if (this.BirthPlace == null || this.BirthPlace === undefined || this.BirthPlace === "")
		{
			modelResponse.MessageContent = "Tempat lahir tidak boleh kosong.";
		}
		else if (this.BirthDate == null || this.BirthDate === undefined)
		{
			modelResponse.MessageContent = "Tanggal lahir tidak boleh kosong.";
		}
		else if (this.IdentificationNumber == null || this.IdentificationNumber === undefined || this.IdentificationNumber === "")
		{
			modelResponse.MessageContent = "KTP tidak boleh kosong.";
		}
		else if (!regExNumeric.test(this.IdentificationNumber))
		{
			modelResponse.MessageContent = "KTP hanya boleh angka.";
		}
		else if ((this.IdentificationNumber.length < RuleConstant.NUMBER_IDENTIFICATIONNUMBER_KTP_LENGTH))
		{
			modelResponse.MessageContent = "KTP minimal " + RuleConstant.NUMBER_IDENTIFICATIONNUMBER_KTP_LENGTH + " karakter.";
		}
		else if ((this.IdentificationNumber.length > RuleConstant.NUMBER_IDENTIFICATIONNUMBER_KTP_LENGTH))
		{
			modelResponse.MessageContent = "KTP maksimal " + RuleConstant.NUMBER_IDENTIFICATIONNUMBER_KTP_LENGTH + " karakter.";
		}
		else if (this.BranchID == null || this.BranchID === undefined)
		{
			modelResponse.MessageContent = "Cabang tidak boleh kosong.";
		}
		else if (this.PhoneNumber == null || this.PhoneNumber === undefined || this.PhoneNumber === "")
		{
			modelResponse.MessageContent = "Nomor handphone tidak boleh kosong.";
		}
		else if (!regExNumeric.test(this.PhoneNumber))
		{
			modelResponse.MessageContent = "Nomor Handphone hanya boleh angka.";
		}
		else if (this.TaxNumber == null || this.TaxNumber === undefined || this.TaxNumber === "")
		{
			modelResponse.MessageContent = "NPWP tidak boleh kosong.";
		}
		else if (this.MaritalStatus == null || this.MaritalStatus === undefined)
		{
			modelResponse.MessageContent = "Status Pernikahan tidak boleh kosong.";
		}
		else if (this.ReligionType == null || this.ReligionType === undefined)
		{
			modelResponse.MessageContent = "Status Agama tidak boleh kosong.";
		}
		else if (this.BRIAccountNumber == null || this.BRIAccountNumber === undefined)
		{
			modelResponse.MessageContent = "Nomor Rekening BRI tidak boleh kosong.";
		}
		else if (this.Address == null || this.Address === undefined || this.Address === "")
		{
			modelResponse.MessageContent = "Address tidak boleh kosong.";
		}
		else if (this.MothersMaidenName == null || this.MothersMaidenName === undefined)
		{
			modelResponse.MessageContent = "Nama Gadis Ibu Kandung tidak boleh kosong.";
		}
		else if (this.Email == null || this.Email === undefined)
		{
			modelResponse.MessageContent = "Email tidak boleh kosong.";
		}
		else if (this.BranchID == null || this.BranchID === undefined)
		{
			modelResponse.MessageContent = "Branch tidak boleh kosong.";
		}
		else if (!regExAlphaNumericForAddress.test(this.Address))
		{
			modelResponse.MessageContent = "Format input Alamat mengandung karakter yang tidak diizinkan.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	//endregion


	//#region CHECK

	checkGroupAccessAgent(stringAccessUser: string): boolean
	{
		const modelUserGroupAccess: UserGroupAccessModel = new UserGroupAccessModel();
		let booleanGroupAccess: boolean = false;

		if(this.listModelUserGroupAccess != null || this.listModelUserGroupAccess !== undefined)
		{
			const arrayModelUserGroupAccessTemp: Array<UserGroupAccessModel> = this.listModelUserGroupAccess.filter(modelUserGroupAccessFilter =>
				modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.Agent);

			if(arrayModelUserGroupAccessTemp[0] === undefined)
			{
				booleanGroupAccess = false;
			}
			else
			{
				modelUserGroupAccess.setModelFromString(JSON.stringify(arrayModelUserGroupAccessTemp[0]));

				if(stringAccessUser === StringConstant.STRING_ACCESS_AMS_AGENT)
				{
					if(modelUserGroupAccess.UserGroupID === ENUM_USERGROUPID.Agent)
					{
						booleanGroupAccess = true;
					}
					else
					{
						booleanGroupAccess = false;
					}
				}
				else
				{
					booleanGroupAccess = false;
				}
			}
		}
		else
		{
			booleanGroupAccess = false;
		}

		return booleanGroupAccess;
	}

	checkGroupAccessAdmin(stringAccessUser: string): boolean
	{
		const modelUserGroupAccess: UserGroupAccessModel = new UserGroupAccessModel();
		let booleanGroupAccess: boolean = false;

		if(this.listModelUserGroupAccess != null || this.listModelUserGroupAccess !== undefined)
		{
			const arrayModelUserGroupAccessTemp: Array<UserGroupAccessModel> = this.listModelUserGroupAccess.filter(modelUserGroupAccessFilter =>
				modelUserGroupAccessFilter.UserGroupID === ENUM_USERGROUPID.Admin);

			if(arrayModelUserGroupAccessTemp[0] === undefined)
			{
				booleanGroupAccess = false;
			}
			else
			{
				modelUserGroupAccess.setModelFromString(JSON.stringify(arrayModelUserGroupAccessTemp[0]));

				if(stringAccessUser === StringConstant.STRING_ACCESS_AMS_ADMIN)
				{
					if(modelUserGroupAccess.UserGroupID === ENUM_USERGROUPID.Admin)
					{
						booleanGroupAccess = true;
					}
					else
					{
						booleanGroupAccess = false;
					}
				}
				else
				{
					booleanGroupAccess = false;
				}
			}
		}
		else
		{
			booleanGroupAccess = false;
		}

		return booleanGroupAccess;
	}

	checkSessionSignin(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("User");

		if (this.getName() == null || this.getName() === undefined || this.getName() === "" || this.getName() === "-")
		{
			modelResponse.MessageTitle = "User";
			modelResponse.MessageContent = "Harap login terlebih dahulu.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}


	//#endregion
}

//#endregion