//#region IMPORT

import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SessionService } from "src/app/services/session.service";
import { NotificationModel } from "src/app/models/notification.model";
import { CustomerService } from "src/app/services/customer.service";
import { ResponseModel } from "src/app/models/response.model";
import { HandshakeModel } from "src/app/models/handshake.model";
import { UserModel } from "src/app/models/user.model";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { BaseAuthourizeDetailComponent } from "../bases/baseauthourizedetail.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-notification",
		templateUrl: "./notification.component.html",
		styleUrls: ["./notification.component.sass"]
	}
)

//#endregion


//#region CLASS

export class NotificationComponent extends BaseAuthourizeDetailComponent
{
	//#region DECLARATION

	public _modelNotification: NotificationModel;
	public _modelHandshake: HandshakeModel;
	public _modelUser: UserModel;


	//#endregion


	//#region CONSTRUCTOR

	constructor(routeActivated: ActivatedRoute, location: Location, serviceSession: SessionService, router: Router, private _serviceCustomer: CustomerService)
	{
		super(routeActivated, location, serviceSession, router);

		this._modelNotification = new NotificationModel();
		this._modelHandshake = new HandshakeModel();
		this._modelUser = new UserModel();
	}


	//#endregion


	//#region ONINIT

	ngOnInit()
	{
		this.callSelectNotificationAgentByUserID(this);
	}

	//#endregion


	//#region WEB SERVICE

	private callSelectNotificationAgentByUserID(componentCurrent: this): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._serviceCustomer.selectNotificationAgentByUserID
		({
			success(modelResponse: ResponseModel): void
			{
				if(modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if(modelResponse.Data !== undefined && modelResponse.Data !== null)
					{
						componentCurrent._modelNotification.setModelFromString(modelResponse.Data);

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, ()=> {});
					}
				}

			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, ()=> { componentCurrent.callSelectNotificationAgentByUserID(componentCurrent);});
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, ()=> { componentCurrent.signOut();});
			}
		}, componentCurrent._modelUser);

	}

	//#endregion
}

//#endregion