//#region IMPORT

import { HandshakeModel } from "src/app/models/handshake.model";
import { ENUM_RESPONSE_STATE, ENUM_AGENT_TYPE } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { ResponseModel } from "./response.model";
import { SupportingDocumentModel } from "./supportingdocument.model";

//#endregion


//#region CLASS

export class AgencyDocumentModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for Agency Document model, used for link to customer model.
		Author: Rafi Indrajati.
		Created on : Saturday, 21 September 2024. 				Updated on :Saturday, 21 September 2024.
		Created by : Rafi Indrajati.							Updated by : Rafi Indrajati.
		Version : 1.0:0.
	*/

	AgentType?: ENUM_AGENT_TYPE;
	AgentToken?: string;
	CertificateExpirationDate?: Date;
	CertificateExpiration?: Date;
	Token?: string;
	listModelAgentSupportingDocument?: Array<SupportingDocumentModel>;
	listModelAgentDocumentModel?: Array<SupportingDocumentModel>;
	modelHandshake?: HandshakeModel;



	/* Attribute - END */

	/* CONSTRUCTOR - START */

	constructor()
	{
		super();
		this.listModelAgentSupportingDocument = [];
	}

	/* CONSTRUCTOR - END */

	/* GETTER - START */

	/* GETTER - END */

	/* VALIDATION - START */

	validateDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Support");
		// if(this.listModelAgentSupportingDocument !== null || this.listModelAgentSupportingDocument !== undefined)
		// {
		// 	modelResponse.MessageContent = "Form is filled correctly.";
		// 	modelResponse.State = ENUM_RESPONSE_STATE.Success;
		// }
		// else
		// {
		// 	modelResponse.MessageContent = "Attachment Tidak Boleh Kosong!.";
		// }
		if(this.listModelAgentSupportingDocument && this.AgentType === 1)
		{
			for(let numberDoc = 0; numberDoc < this.listModelAgentSupportingDocument?.length; numberDoc++)
			{
				if ((this.listModelAgentSupportingDocument[0].Name == null && this.listModelAgentSupportingDocument[0].Data == null) || (this.listModelAgentSupportingDocument[0].Name === undefined && this.listModelAgentSupportingDocument[0].Data === undefined))
				{
					modelResponse.MessageContent = "KTP Tidak Boleh Kosong!.";
				}
				else if ((this.listModelAgentSupportingDocument[1].Name == null && this.listModelAgentSupportingDocument[1].Data == null) || (this.listModelAgentSupportingDocument[1].Name === undefined && this.listModelAgentSupportingDocument[1].Data === undefined))
				{
					modelResponse.MessageContent = "NPWP Tidak Boleh Kosong!.";
				}
				else if ((this.listModelAgentSupportingDocument[2].Name == null && this.listModelAgentSupportingDocument[2].Data == null) || (this.listModelAgentSupportingDocument[2].Name === undefined && this.listModelAgentSupportingDocument[2].Data === undefined))
				{
					modelResponse.MessageContent = "NPWP Badan Usaha Tidak Boleh Kosong!.";
				}
				else if ((this.listModelAgentSupportingDocument[3].Name == null && this.listModelAgentSupportingDocument[3].Data == null) || (this.listModelAgentSupportingDocument[3].Name === undefined && this.listModelAgentSupportingDocument[3].Data === undefined))
				{
					modelResponse.MessageContent = "Buku Tabungan BRI an. Perwakilan Badan Usaha Tidak Boleh Kosong!.";
				}
				else if ((this.listModelAgentSupportingDocument[5].Name == null && this.listModelAgentSupportingDocument[5].Data == null) || (this.listModelAgentSupportingDocument[5].Name === undefined && this.listModelAgentSupportingDocument[5].Data === undefined))
				{
					modelResponse.MessageContent = "Akta Notaris Tidak Boleh Kosong!.";
				}
				else if ((this.listModelAgentSupportingDocument[8].Name == null && this.listModelAgentSupportingDocument[8].Data == null) || (this.listModelAgentSupportingDocument[8].Name === undefined && this.listModelAgentSupportingDocument[8].Data === undefined))
				{
					modelResponse.MessageContent = "NIB Tidak Boleh Kosong!.";
				}
				else
				{
					modelResponse.MessageContent = "Form is filled correctly.";
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			}
		}
		else if(this.listModelAgentSupportingDocument && this.AgentType === 0)
		{
			for(let numberDoc = 0; numberDoc < this.listModelAgentSupportingDocument?.length; numberDoc++)
			{
				if ((this.listModelAgentSupportingDocument[0].Name == null && this.listModelAgentSupportingDocument[0].Data == null) || (this.listModelAgentSupportingDocument[0].Name === undefined && this.listModelAgentSupportingDocument[0].Data === undefined))
				{
					modelResponse.MessageContent = "KTP Tidak Boleh Kosong!.";
				}
				else if ((this.listModelAgentSupportingDocument[1].Name == null && this.listModelAgentSupportingDocument[1].Data == null) || (this.listModelAgentSupportingDocument[1].Name === undefined && this.listModelAgentSupportingDocument[1].Data === undefined))
				{
					modelResponse.MessageContent = "NPWP Tidak Boleh Kosong!.";
				}
				else if ((this.listModelAgentSupportingDocument[2].Name == null && this.listModelAgentSupportingDocument[2].Data == null) || (this.listModelAgentSupportingDocument[2].Name === undefined && this.listModelAgentSupportingDocument[2].Data === undefined))
				{
					modelResponse.MessageContent = "Buku Tabungan BRI Tidak Boleh Kosong!.";
				}
				else
				{
					modelResponse.MessageContent = "Form is filled correctly.";
					modelResponse.State = ENUM_RESPONSE_STATE.Success;
				}
			}
		}
		else
		{
			modelResponse.MessageContent = "Attachment Tidak Boleh Kosong!.";
		}
		return modelResponse;
	}

	/* VALIDATION - END */
}



//#endregion