//#region  CLASS

export class RegexFunction
{
	/*
		Attribute - START
		Description : Regex Function.
		Author: Andryana Baros.
		Created on : Tuesday, 17 January 2023. 			Updated on : Wednesday, 18 January 2023.
		Created by : Billy Johannes							Updated by : Billy Johannes.
		Version : 1.0.1
	*/

	public regexAlfabetWithSpace(event)
	{
		const regularExpressionAlfabetWithSpace: RegExp = /^[a-zA-Z ]+$/;

		const stringRegex = String.fromCharCode(!event.charCode ? event.which : event.charCode);
		if (regularExpressionAlfabetWithSpace.test(stringRegex))
		{
			return true;
		}
		event.preventDefault();
		return false;
	}

	public regexNumericOnly(event)
	{
		const regularExpressionAlfabetWithSpace: RegExp = /^\d+$/;

		const stringRegex = String.fromCharCode(!event.charCode ? event.which : event.charCode);
		if (regularExpressionAlfabetWithSpace.test(stringRegex))
		{
			return true;
		}
		event.preventDefault();
		return false;
	}

	public regexMobilePhone(event)
	{
		const regularExpressionAlfabetWithSpace: RegExp = /^[+]?[0-9]{10,15}$/;

		const stringRegex = String.fromCharCode(!event.charCode ? event.which : event.charCode);
		if (regularExpressionAlfabetWithSpace.test(stringRegex))
		{
			return true;
		}
		event.preventDefault();
		return false;
	}

	public regexAplhaNumeric(event)
	{
		const regularExpressionAlfabetWithSpace: RegExp = /^[a-zA-Z0-9]*$/;

		const stringRegex = String.fromCharCode(!event.charCode ? event.which : event.charCode);
		if (regularExpressionAlfabetWithSpace.test(stringRegex))
		{
			return true;
		}
		event.preventDefault();
		return false;
	}

	public regexAplhaNumericWithSpace(event)
	{
		const regularExpressionAlfabetWithSpace: RegExp = /^[a-zA-Z0-9 ]*$/;

		const stringRegex = String.fromCharCode(!event.charCode ? event.which : event.charCode);
		if (regularExpressionAlfabetWithSpace.test(stringRegex))
		{
			return true;
		}
		event.preventDefault();
		return false;
	}

	public regexEmail(event)
	{
		const regularExpressionAlfabetWithSpace: RegExp = /^[A-Z0-9a-z@.]$/;

		const stringRegex = String.fromCharCode(!event.charCode ? event.which : event.charCode);
		if (regularExpressionAlfabetWithSpace.test(stringRegex))
		{
			return true;
		}
		event.preventDefault();
		return false;
	}

	public regexAplhaOnly(event)
	{
		const regularExpressionAlfabetWithSpace: RegExp = /^[A-Za-z]$/;

		const stringRegex = String.fromCharCode(!event.charCode ? event.which : event.charCode);
		if (regularExpressionAlfabetWithSpace.test(stringRegex))
		{
			return true;
		}
		event.preventDefault();
		return false;
	}

	public regexAplhaNumericWithStrip(event)
	{
		const regularExpressionAlfabetWithSpace: RegExp = /^[a-zA-Z0-9-/]*$/;

		const stringRegex = String.fromCharCode(!event.charCode ? event.which : event.charCode);
		if (regularExpressionAlfabetWithSpace.test(stringRegex))
		{
			return true;
		}
		event.preventDefault();
		return false;
	}

	public regexNumberWithDash(event)
	{
		const regularExpressionNumberWithDash: RegExp = /^[0-9\-\s]+$/i;

		const stringRegex = String.fromCharCode(!event.charCode ? event.which : event.charCode);
		if (regularExpressionNumberWithDash.test(stringRegex))
		{
			return true;
		}
		event.preventDefault();
		return false;
	}

	public regexAlfanumericSpecialCharacter(event)
	{
		const regularExpressionAlfanumericSpecialCharacter: RegExp = /^[A-Z0-9a-z.\-_\s]+$/i;

		const stringRegex = String.fromCharCode(!event.charCode ? event.which : event.charCode);
		if (regularExpressionAlfanumericSpecialCharacter.test(stringRegex))
		{
			return true;
		}
		event.preventDefault();
		return false;
	}

	/* Attribute - END */
}

// #endregion