<div id="divSignInContainer">
	<section class="SectionFormSignInContainer">
		<ng-container>
			<header class="HeaderSignIn">
				<img src="../../../assets/icons/sakina/icon_bri_insurance.svg" alt="BRI Insurance - Logo BRI Insurance">
			</header>
			<header class="HeaderSignInResponsive">
				<img src="../../../assets/icons/sakina/icon_bri_insurance.svg" alt="BRI Insurance - Logo BRI Insurance">
				<img src="../../assets/logos/sakina/logo_ams.svg" alt="BRI Insurance - Logo AMS">
			</header>
		</ng-container>
		<form>
			<div class="DivFormHeader" style="text-align: center;">
				<h1>Edit Profile</h1>
				<label>Ubah profil Anda disini</label>
			</div>

			<div class="DivForm" style="padding: 5vw;">
				<h3>Akun</h3>
				<div class="DivFormHorizontalContainer">
					<div class="DivForm2Column" style="margin-top: 0px;">
						<fieldset>
							<section class="SectionFormInput">
								<div>
									<p style="margin:0" for="textFirstName" class="LabelPlaceholderGeneral">Sebagai</p>
									<label style="margin:0; font-family: Poppins-Regular; color:black" class="ParagraphSmall">{{_modelUserPublic.getAgentTypeName()}}</label>
								</div>
							</section>
						</fieldset>
					</div>	
				</div>

				<h3>Data</h3>
				<div class="DivFormHorizontalContainer">
					<div class="DivForm2Column" style="margin-top: 0px;">
						<fieldset>
							<section class="SectionFormInput">
								<div class="DivFormInput">
									<p for="textFullName" class="LabelPlaceholderGeneral">Nama Lengkap (Sesuai KTP)</p>
									<input type="text" id="textFirstName" name="modelUserFullName"
										[ngModelOptions]="{standalone: true}" placeholder="Masukkan Nama Lengkap"
										autocomplete="off" [(ngModel)]="_modelUserPublic.FullName">
								</div>
							</section>


							<p style="margin:0" for="radioButtonLicensePlateCalculate" class="SemiBold">Jenis Kelamin
							</p>
							<div style="margin:0;height: 50px;" id="valueContainer" class="DivRadioButtonContainer">
								<label class="LabelRadioButtonContainer" for="radioButtonForMale"
									[ngClass]="{ 'LabelRadioButtonActive' : _booleanGender}">
									<input type="radio" name="radioButtonForMale" [(ngModel)]="_booleanGender"
										[ngModelOptions]="{standalone: true}" id="radioButtonForMale"
										[value]=1>Laki-Laki
								</label>

								<label class="LabelRadioButtonContainer" for="radioButtonForFemale"
									[ngClass]="{ 'LabelRadioButtonActive' : _booleanGender}">
									<input type="radio" name="radioButtonForFemale" [(ngModel)]="_booleanGender"
										[ngModelOptions]="{standalone: true}" id="radioButtonForFemale"
										[value]=0>Perempuan
								</label>
							</div>

							<section class="SectionFormInput">
								<div class="DivFormInput">
									<p for="textBirthPlace" class="LabelPlaceholderGeneral">Tempat Lahir</p>
									<input type="text" id="textBirthPlace" name="modelUserBirthPlace"
										[ngModelOptions]="{standalone: true}" placeholder="Masukkan Tempat Lahir"
										autocomplete="off" [(ngModel)]="_modelUserPublic.BirthPlace">
								</div>
							</section>


							<section class="SectionFormInput">
								<div class="DivFormInput">
									<p for="dateModelBirthDate" class="LabelPlaceholderGeneral">Tanggal Lahir*</p>
									<div class="DivDateTimePicker">
										<mat-form-field class="matFormFieldPolicyholderExpiredDrivingLicense"
											appearance="fill">
											<input id="dateModelProductGeneralPolicyholderExpiredDrivingLicense"
												[(ngModel)]="_modelUserPublic.BirthDate"
												[ngModelOptions]="{standalone: true}" matInput
												[matDatepicker]="dpBornDate" placeholder="Pilih Tanggal Lahir">
											<mat-datepicker-toggle matSuffix [for]="dpBornDate"></mat-datepicker-toggle>
											<mat-datepicker #dpBornDate startView="month" panelClass="month-picker">
											</mat-datepicker>
										</mat-form-field>
									</div>
								</div>
							</section>

							<section class="SectionFormInput">
								<div class="DivFormInput">
									<p for="textEmail" class="LabelPlaceholderGeneral">Email</p>
									<input type="text" id="textEmail" name="modelUserEmail" placeholder="Masukkan Email"
										[ngModelOptions]="{standalone: true}" autocomplete="off"
										[(ngModel)]="_modelUserPublic.Email">
								</div>
							</section>

							<div class="DivFormMobilePhoneContainer">
								<section class="SectionFormInput">
									<div class="DivFormInput">
										<p for="textCountryCode" class="LabelPlaceholderGeneral">Kode</p>
										<input type="text" id="textCountryCode" name="modelUserCountryCodel"
											placeholder="+62" autocomplete="off" disabled>
									</div>
								</section>
								<section class="SectionFormInput">
									<div class="DivFormInput">
										<p for="textPhoneNumber" class="LabelPlaceholderGeneral">Nomor Ponsel</p>
										<input type="text" id="textPhoneNumber" name="modelUserPhoneNumber"
											[ngModelOptions]="{standalone: true}" placeholder="Masukkan Nomor Ponsel"
											autocomplete="off" [(ngModel)]="_modelUserPublic.PhoneNumber">
									</div>
								</section>
							</div>

							<section class="SectionFormInput">
								<div class="DivFormInput">
									<p for="textID" class="LabelPlaceholderGeneral">KTP</p>
									<input type="text" id="textID" name="modelUserID"
										[ngModelOptions]="{standalone: true}" placeholder="Masukkan Nomor KTP"
										autocomplete="off" [(ngModel)]="_modelUserPublic.IdentificationNumber">
								</div>
							</section>

							<section class="SectionFormInput">
								<div class="DivFormInput">
									<p for="textID" class="LabelPlaceholderGeneral">NPWP</p>
									<input type="text" id="textID" name="modelUserID" placeholder="Masukkan NPWP"
										[ngModelOptions]="{standalone: true}" autocomplete="off"
										[(ngModel)]="_modelUserPublic.TaxNumber">
								</div>
							</section>

						</fieldset>
					</div>

					<div class="DivForm2Column">
						<fieldset>
							<section class="SectionFormInput">
								<div class="DivFormInput">
									<p for="selectMaritalStatus" class="LabelPlaceholderGeneral">Status Perkawinan</p>
									<select [(ngModel)]="_modelUserPublic.MaritalStatus" name="selectMaritalStatus">
										<option [ngValue]="_modelUndefined">Pilih Status Perkawinan</option>
										<option [ngValue]="_enumMaritalType.Single">Lajang</option>
										<option [ngValue]="_enumMaritalType.Married">Menikah</option>
										<option [ngValue]="_enumMaritalType.Divorce">Cerai Hidup</option>
										<option [ngValue]="_enumMaritalType.Widow">Cerai Mati</option>
									</select>
								</div>
							</section>

							<section class="SectionFormInput">
								<div class="DivFormInput">
									<p for="selectReligion" class="LabelPlaceholderGeneral">Agama</p>
									<select [(ngModel)]="_modelUserPublic.ReligionType" name="selectReligion">
										<option [ngValue]="_modelUndefined">Pilih Agama</option>
										<option [ngValue]="_enumReligion.Islam">Islam</option>
										<option [ngValue]="_enumReligion.Christianity">Kristen</option>
										<option [ngValue]="_enumReligion.Catholicism">Katolik</option>
										<option [ngValue]="_enumReligion.Buddhism">Buddha</option>
										<option [ngValue]="_enumReligion.Hinduism">Hindu</option>
										<option [ngValue]="_enumReligion.Confucianism">Konghucu</option>
									</select>
								</div>
							</section>

							<section class="SectionFormInputTextArea">
								<div class="DivFormInputTextarea">
									<p>Alamat</p>
									<textarea name="textAddress" placeholder="Masukkan Detail Alamat"
										[(ngModel)]="_modelUserPublic.Address"></textarea>
								</div>
							</section>

							<section class="SectionFormInput">
								<div class="DivFormInput">
									<p for="textAccountNumber" class="LabelPlaceholderGeneral">Nomor Rekening BRI</p>
									<input type="text" id="textAccountNumber" name="modelUserAccountNumber"
										[ngModelOptions]="{standalone: true}" placeholder="Masukkan Nomor Rekening BRI"
										autocomplete="off" [(ngModel)]="_modelUserPublic.BRIAccountNumber">
								</div>
							</section>

							<section class="SectionFormInput">
								<div class="DivFormInput">
									<p for="selectBranch" class="LabelPlaceholderGeneral">Cabang</p>
									<select [(ngModel)]="_modelUserPublic.BranchID" name="selectBranch">
										<option [ngValue]="_modelUndefined">Pilih Cabang</option>
										<option *ngFor="let modelBranch of _arrayBranch" [value]="modelBranch.ID">
											{{ modelBranch.Name }}
										</option>
									</select>
								</div>
							</section>
							<section class="SectionFormInput">
								<div class="DivFormInput">
									<p for="textMotherMaiden" class="LabelPlaceholderGeneral">Nama Ibu Kandung</p>
									<input type="text" id="textMotherMaiden" name="modelUserMotherMaiden"
										[ngModelOptions]="{standalone: true}" placeholder="Masukkan Nama Ibu Kandung"
										autocomplete="off" [(ngModel)]="_modelUserPublic.MothersMaidenName">
								</div>
							</section>

							<section *ngIf="_modelUserPublic.modelAgent?.AgentType === 1" class="SectionFormInput">
								<div class="DivFormInput">
									<p for="textID" class="LabelPlaceholderGeneral">Nama Perusahaan</p>
									<input type="text" id="textCompanyName" name="modelUserCompanyName"
										placeholder="Masukkan Nama Perusahaan" [ngModelOptions]="{standalone: true}"
										autocomplete="off" [(ngModel)]="_modelUserPublic.CompanyName">
								</div>
							</section>
						</fieldset>
					</div>
				</div>

				<h3>Sertifikat</h3>
				<div *ngFor="let listCertificate of  _modelUserDocument.listModelAgentDocumentModel">
					<div *ngIf="listCertificate.DocumentType === 4 && listCertificate.Data === undefined">
						<p for="radioButtonLicensePlateCalculate" class="SemiBold">Apakah Anda mempunyai sertifikat?</p>
						<div class="DivFormHorizontalContainer">
							<div class="DivForm2Column">
								<div class="DivRadioButtonContainer">
									<label class="LabelRadioButtonContainer" for="radioButtonLicensePlateForCalculateTrue"
										[ngClass]="{ 'LabelRadioButtonActive' : _booleanCertificate}">
										<input type="radio" name="radioButtonLicensePlateForCalculateTrue"
											[(ngModel)]="_booleanCertificate" id="radioButtonLicensePlateForCalculateTrue"
											[value]=true>Ya
									</label>
		
									<label class="LabelRadioButtonContainer" for="radioButtonLicensePlateForCalculateFalse"
										[ngClass]="{ 'LabelRadioButtonActive' : _booleanCertificate}">
										<input type="radio" name="radioButtonLicensePlateForCalculateFalse"
											[(ngModel)]="_booleanCertificate" id="radioButtonLicensePlateForCalculateFalse"
											[value]=false>Tidak
									</label>
								</div>

								<fieldset *ngIf="_booleanCertificate === true">
									<section class="SectionFormInput">
										<div class="DivFormInput" [ngClass]="{'DivFormInputWithAttachment':listCertificate.Name}">
											<figure *ngIf="listCertificate.Name" class="FigureInputAttachment">
												<img src="{{listCertificate.Extension | convertExtensionToIcon }}"
													alt="{{listCertificate.Name }}">
											</figure>
											<p for="fileCertificateDocs" class="LabelPlaceholderSignIn">{{listCertificate.getSupportingDocumentName()}}</p>
											<label class="LabelInputFile" for="fileCertificateDocs">
												<input type="file" id="fileCertificateDocs" name="modelUserFileCertificate"
													(change)="listenFileUpload($event, listCertificate.getSupportingDocumentEnum())" placeholder="Masukkan Sertifikat"
													autocomplete="off">
												<span>{{listCertificate.Name !== "" ? listCertificate.Name : "Unggah Sertifikat with Max Size 20 MB"   }}</span>
											</label>
										</div>
									</section>
								</fieldset>
							</div>
							<div class="DivForm2Column">
								<fieldset>
									<section *ngIf="_booleanCertificate" class="SectionFormInput">
										<div class="DivFormInput">
											<p for="dateModelPeriodDate" class="LabelPlaceholderGeneral">Tanggal Kadaluarsa
												Sertifikat*</p>
											<div class="DivDateTimePicker">
												<mat-form-field class="matFormFieldPolicyholderExpiredDrivingLicense"
													appearance="fill">
													<input id="dateModelPeriodDate" [ngModelOptions]="{standalone: true}" (dateChange)="onDateChange($event)"
														matInput [matDatepicker]="dpPeriodDate" placeholder="Pilih Tanggal"
														[(ngModel)]="_modelUserPublic.CertificateExpirationDate">
													<mat-datepicker-toggle matSuffix
														[for]="dpPeriodDate"></mat-datepicker-toggle>
													<mat-datepicker #dpPeriodDate startView="month" panelClass="month-picker">
													</mat-datepicker>
												</mat-form-field>
											</div>
										</div>
									</section>
								</fieldset>
								
							</div>
						</div>
					</div>
					<div *ngIf="listCertificate.DocumentType === 4 && listCertificate.Data !== undefined">
						<div class="DivFormHorizontalContainer">
							<div class="DivForm2Column">
								<fieldset *ngIf=" _booleanCertificate === true">
									<section class="SectionFormInput">
										<div class="DivFormInput" [ngClass]="{'DivFormInputWithAttachment':listCertificate.Name}">
											<figure *ngIf="listCertificate.Name" class="FigureInputAttachment">
												<img src="{{listCertificate.Extension | convertExtensionToIcon }}"
													alt="{{listCertificate.Name}}">
											</figure>
											<p for="fileCertificateDocs" class="LabelPlaceholderSignIn">{{listCertificate.getSupportingDocumentName()}}</p>
											<label class="LabelInputFile" for="fileCertificateDocs">
												<input type="file" id="fileCertificateDocs" name="modelUserFileCertificate"
													(change)="listenFileUpload($event, listCertificate.getSupportingDocumentEnum())" placeholder="Masukkan Sertifikat"
													autocomplete="off">
												<span>{{listCertificate.Name !== "" ? listCertificate.Name : "Unggah Sertifikat with Max Size 20 MB" }}</span>
											</label>
										</div>
									</section>
								</fieldset>
							</div>

							<div class="DivForm2Column">
								<fieldset>
									<section *ngIf="_booleanCertificate" class="SectionFormInput">
										<div class="DivFormInput">
											<p for="dateModelPeriodDate" class="LabelPlaceholderGeneral">Tanggal Kadaluarsa
												Sertifikat*</p>
											<div class="DivDateTimePicker">
												<mat-form-field class="matFormFieldPolicyholderExpiredDrivingLicense"
													appearance="fill">
													<input id="dateModelPeriodDate" [ngModelOptions]="{standalone: true}" (dateChange)="onDateChange($event)"
														matInput [matDatepicker]="dpPeriodDate" placeholder="Pilih Tanggal"
														[(ngModel)]="_modelUserPublic.CertificateExpirationDate">
													<mat-datepicker-toggle matSuffix
														[for]="dpPeriodDate"></mat-datepicker-toggle>
													<mat-datepicker #dpPeriodDate startView="month" panelClass="month-picker">
													</mat-datepicker>
												</mat-form-field>
											</div>
										</div>
									</section>
								</fieldset>
							</div>
						</div>
					</div>
				</div>

				<h3>Dokumen Pendukung</h3>
					<div class="DivFormHorizontalContainer" >
						<ng-container *ngFor="let listSupportingDocument of  _modelUserDocument.listModelAgentDocumentModel">
							<ng-container *ngIf="listSupportingDocument.DocumentType !== 4 && listSupportingDocument.DocumentType !== 9 && listSupportingDocument.DocumentType !== 10">
								<div class="DivForm2Column" >
									<fieldset >
										<section class="SectionFormInput">
											<div class="DivFormInput" [ngClass]="{'DivFormInputWithAttachment':listSupportingDocument.Name}">
												<figure *ngIf="listSupportingDocument.Name" class="FigureInputAttachment">
													<img src="{{listSupportingDocument.Extension | convertExtensionToIcon }}"
														alt="{{listSupportingDocument.Name}}">
												</figure>
												<p class="LabelPlaceholderSignIn">{{listSupportingDocument.getSupportingDocumentName()}}</p>
												<label class="LabelInputFile" [for]="'file' + listSupportingDocument.getSupportingDocumentName() + 'Docs'">
													<input type="file" [id]="'file' + listSupportingDocument.getSupportingDocumentName() + 'Docs'" [name]="'modelUserFile' + listSupportingDocument.getSupportingDocumentName()"
														(change)="listenFileUpload($event, listSupportingDocument.getSupportingDocumentEnum())"
														autocomplete="off">
													<span>{{listSupportingDocument.Name !== "" ? listSupportingDocument.Name : "Unggah PDF dengan maksimal ukuran 20 MB" }}</span>
												</label>
											</div>
										</section>
									</fieldset>
								</div>
							</ng-container>
							
						</ng-container>
					
					</div>
				
				<h3>E-PKS</h3>
				<div class="DivFormHorizontalContainer" >
					<ng-container *ngFor="let listSupportingDocument of  _modelUserDocument.listModelAgentDocumentModel">
						<ng-container *ngIf="listSupportingDocument.DocumentType === 9  && _modelUserSignIn.checkGroupAccessAgent(_stringConstant.STRING_ACCESS_AMS_AGENT)">
							<div class="DivForm2Column" >
								<fieldset >
									<section class="SectionFormInput">
										<div class="DivFormInput" [ngClass]="{'DivFormInputWithAttachment':listSupportingDocument.Name}">
											<figure *ngIf="listSupportingDocument.Name" class="FigureInputAttachment">
												<img src="{{listSupportingDocument.Extension | convertExtensionToIcon }}"
													alt="{{listSupportingDocument.Name}}">
											</figure>
											<p class="LabelPlaceholderSignIn">{{listSupportingDocument.getSupportingDocumentName()}}</p>
											<label class="LabelInputFile" [for]="'file' + listSupportingDocument.getSupportingDocumentName() + 'Docs'">
												<input type="file" [id]="'file' + listSupportingDocument.getSupportingDocumentName() + 'Docs'" [name]="'modelUserFile' + listSupportingDocument.getSupportingDocumentName()"
													(change)="listenFileUpload($event, listSupportingDocument.getSupportingDocumentEnum())"
													autocomplete="off">
												<span>{{listSupportingDocument.Name !== "" ? listSupportingDocument.Name : "Unggah PDF dengan maksimal ukuran 20 MB" }}</span>
											</label>
										</div>
									</section>
								</fieldset>
							</div>
						</ng-container>
						
					</ng-container>
				
				</div>
			</div>
			<div class="DivButtonContainerSmallCenter">
				<input type="button" value="PERBARUI" (click)="callUpdate()">
			</div>
		</form>
		<div class="DivAlignCenterContainer">
			<p>Already have an account? <a><b>Sign In</b></a></p>
		</div>
	</section>
</div>