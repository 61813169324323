<div id="divSignInContainer">
    <section class="SectionBubbleCard">
        <div class="DivContentBubleCard">
            <section class="SectionFormSignInContainer">
                <ng-container>
                    <header class="HeaderSignIn">
                        <img src="../../../assets/icons/sakina/icon_bri_insurance.svg" alt="BRI Insurance - Logo BRI Insurance">
                    </header>
                    <header class="HeaderSignInResponsive">
                        <img src="../../../assets/icons/sakina/icon_bri_insurance.svg" alt="BRI Insurance - Logo BRI Insurance">
                        <img src="../../assets/logos/sakina/logo_ams.svg" alt="BRI Insurance - Logo AMS">
                    </header>
                </ng-container>
                <form>
                    <div class="DivFormHeader">
                        <h1>Premium Calculator</h1>
                    </div>
                    <div class="DivForm">
                        <fieldset>
                            <section class="SectionFormInput">
                                <div class="DivFormInput">
                                    <label for="textEmail" class="LabelPlaceholderSignIn">Email</label>
                                    <input type="text" class="InputEmail" id="textEmail" name="modelUserEmail" 
                                        placeholder="Masukkan Email" autocomplete="off" >
                                </div>
                            </section>
        
                        </fieldset>
                    </div>
                    <div class="DivButtonContainer">
                        <input type="button" value="KALKULASI" >
                    </div>
                </form>
            </section>
        
            <section class="SectionHeaderSignInContainer">
                <div class="DivHeaderContent">
                    <h1>Agent<br>Management<br>System</h1>
                    <label>
                        Bergabunglah dengan BRI Insurance sebagai agen asuransi dan mulailah perjalanan karir yang penuh peluang
                        dan kesuksesan. Di BRI Insurance, kami menawarkan benefit kompetitif, pelatihan profesional, dan
                        dukungan penuh untuk membantu Anda mencapai potensi maksimal. Dengan berbagai produk asuransi terbaik
                        yang dapat Anda tawarkan sekaligus mengembangkan karir Anda. Jangan lewatkan kesempatan ini untuk
                        menjadi bagian dari Agen BRI Insurance yang dinamis dan inovatif.
                    </label>
        
                    <img id="imgIconRectangleOne" src="../../../assets/icons/sakina/icon_rectangle_one.svg"
                        alt="Header - Icon Rectangle">
                    <img id="imgIconRectangleTwo" src="../../../assets/icons/sakina/icon_rectangle_two.svg"
                        alt="Header - Icon Rectangle">
                    <img id="imgIconRectangleThree" src="../../../assets/icons/sakina/icon_rectangle_three.svg"
                        alt="Header - Icon Rectangle">
                </div>
            </section>
        </div>

    </section>

</div>