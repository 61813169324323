//#region IMPORT

import { Router } from "@angular/router";
import { UserInterfaceFunction } from "src/app/functions/userinterface.function";
import { SessionService } from "src/app/services/session.service";
// import { UserModel } from "src/app/models/user.model";
import { PartialObserver } from "rxjs";
import { UserAgentModel } from "./../../models/useragent.model";

//#endregion


//#region CLASS

export class BasePublicComponent
{
	//#region DECLARATION

	protected _functionUserInterface: UserInterfaceFunction;
	public _modelUserCheck: UserAgentModel;

	//#endregion


	//#region CONSTRUCTOR

	constructor(protected _serviceSession: SessionService, protected _router: Router)
	{
		this._functionUserInterface = new UserInterfaceFunction();
		this._modelUserCheck = new UserAgentModel();

		const observerPartialModelUserSignIn: PartialObserver<UserAgentModel> =
		{
			next: (modelUserAgent: UserAgentModel) => { this._modelUserCheck = modelUserAgent; },
			error: () => { },
			complete: () => { }
		};

		this._serviceSession._modelUserCheck.subscribe(observerPartialModelUserSignIn);
	}

	//#endregion


	//#region FUNCTION

	protected goToSignInWithClearSession(): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();

		this._router.navigate(["signin"]);
	}

	protected goToSignUp(): void
	{
		this._router.navigate(["/signup"]);
	}

	//#endregion
}

//#endregion