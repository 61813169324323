//#region IMPORT

import { ENUM_RESPONSE_STATE, ENUM_USER_ROLE } from "../constants/enum.constant";
import { GeneralModel } from "./bases/general.model";
import { ResponseModel } from "./response.model";

//#endregion

//#region CLASS

export class UserSessionModel extends GeneralModel
{
	/*
		Attribute - START
		Description : Attribute for vehicle user session model, used for user session.
		Author: Andri Septiawan.
		Created on : Monday, 18 January 2021.        		Updated on : Monday, 18 January 2021.
		Created by : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:1.
	*/

	UserRole?: ENUM_USER_ROLE;
	UserToken?: string;

	/* Attribute - END */


	//#region VALIDATION

	validateUserSession(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("User Session");

		if (this.UserToken === undefined || this.UserToken == null)
		{
			modelResponse.MessageContent = "User not logged in, please login!";
		}
		else
		{
			modelResponse.MessageContent = "User is saved in UserSession";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	//#endregion
}

//#endregion