//#region IMPORT

import { ENUM_PROJECT_CODE, ENUM_SERVER_TYPE } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class HandshakeModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for handshake model, used for generate token.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020.				Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						Updated by : Ibrahim Aziz.
		Version : 1.0:3.
	*/

	ID?: number;
	UserID?: number;
	Token?: string;
	RequestKey?: string;
	ResponseKey?: string;
	Server?: ENUM_SERVER_TYPE;
	DeviceID?: string;
	Culture?: string;
	ClientPlatform?: string;
	ClientOperatingSystem?: string;
	ClientDeviceType?: string;
	ClientDeviceBrand?: string;
	ClientApplicationVersion?: string;
	ProjectCode?: ENUM_PROJECT_CODE;

	/* Attribute - END */


	//#region CONSTRUCTOR

	constructor()
	{
		super();

		this.Token = "";
		this.DeviceID = navigator.userAgent;
		// this.Culture = this.setCulture();
		this.Culture = StringConstant.STRING_CULTURE_INDONESIAN;
		this.ProjectCode = ENUM_PROJECT_CODE.Galunggung;
	}

	//#endregion


	//#region FUNCTION

	/*
		attribute - START
		Description : Remove RSA private key header and footer for handshake with server.
		Author : Andri Septiawan.
		Created on  : Wednesday, 13 January 2021.			Updated on : Wednesday, 13 January 2021.
		Created by  : Andri Septiawan.						Updated by : Andri Septiawan.
		Version : 1.0:2.
	*/

	removeHeaderForHandshake(): void
	{
		if (this.RequestKey !== undefined)
		{
			const arrayPublicKey: Array<string> = this.RequestKey.split("\n");
			arrayPublicKey.pop();
			arrayPublicKey.shift();

			this.RequestKey = arrayPublicKey.join("");
		}
		else
		{

		}
	}

	/* attribute - END */

	//#endregion
}

//#endregion