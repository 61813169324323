<div id="divSignInContainer">
	<section class="SectionFormSignInContainer"
		[ngClass]="{ 'FormSignInShow' : _booleanShow, 'FormSignInHide' : !_booleanShow }">
		<ng-container>
			<header class="HeaderSignIn">
				<img src="../../../assets/icons/sakina/icon_bri_insurance.svg" alt="BRI Insurance - Logo BRI Insurance">
			</header>
			<header class="HeaderSignInResponsive">
				<img src="../../../assets/icons/sakina/icon_bri_insurance.svg" alt="BRI Insurance - Logo BRI Insurance">
				<img src="../../assets/logos/sakina/logo_ams.svg" alt="BRI Insurance - Logo AMS">
			</header>
		</ng-container>
		<form>
			<div class="DivFormHeader">
				<h1>Masuk</h1>
				<label>Masukkan alamat email untuk mendapatkan OTP</label>
			</div>
			<div class="DivForm">
				<fieldset>
					<section class="SectionFormInput">
						<div class="DivFormInput">
							<label for="textEmail" class="LabelPlaceholderSignIn">Email</label>
							<input type="text" class="InputEmail" id="textEmail" name="modelUserEmail" 
								placeholder="Masukkan Email" autocomplete="off" [(ngModel)]="_modelUser.Email">
						</div>
					</section>

				</fieldset>
			</div>
			<div class="DivButtonContainer">
				<input type="button" value="MASUK" (click)="callSelectAgentByEmailForSignin(false, '')" >
				<label>Lihat <a><b (click)="goToFAQ()">FAQ</b></a> untuk melihat informasi terkait Agent Management System</label>
			</div>
		</form>
		<div class="DivAlignCenterContainer">
			<label>Butuh Akun? <a (click)="goToSignUp()"><b>Daftar Disini</b></a></label>
		</div>
	</section>

	<section class="SectionFormSignInContainer"
		[ngClass]="{ 'FormSignInShow' : !_booleanShow, 'FormSignInHide' : _booleanShow }">
		<ng-container>
			<header class="HeaderSignIn">
				<img src="../../../assets/icons/sakina/icon_bri_insurance.svg" alt="BRI Insurance - Logo BRI Insurance">
				<label (click)="_booleanShow = false"><b>⭠ Kembali</b></label>
			</header>
			<header class="HeaderSignInResponsive">
				<div class="DivHeaderColumnContainer">
					<img src="../../../assets/icons/sakina/icon_bri_insurance.svg"
						alt="BRI Insurance - Logo BRI Insurance">
					<label>&nbsp;</label>
				</div>
				<div class="DivHeaderColumnContainer">
					<img src="../../assets/logos/sakina/logo_ams.svg" alt="BRI Insurance - Logo AMS">
					<label><b>⭠ Kembali</b></label>
				</div>
			</header>
		</ng-container>
		<form>
			<div class="DivFormHeader">
				<h1>OTP</h1>
				<label>Periksa kotak masuk email anda untuk  melihat Kode OTP</label>
			</div>
			<div class="DivForm">
				<fieldset>
					<div class="DivInputHorizontalContainer">
						<input type="number" id="passwordEmailToken1" class="InputToken" name="stringEmailToken1"
							[(ngModel)]="_arrayNumberEmailToken[0]" (keyup)="listenInputToken($event, 0)"
							(click)="clickInputToken(0);" maxlength="1" max="9" required [disabled]="_booleanInputOTP">
						<input type="number" class="InputToken" name="stringEmailToken2"
							[(ngModel)]="_arrayNumberEmailToken[1]" (keyup)="listenInputToken($event, 1)"
							(click)="clickInputToken(1);" maxlength="1" max="9" required [disabled]="_booleanInputOTP">
						<input type="number" class="InputToken" name="stringEmailToken3"
							[(ngModel)]="_arrayNumberEmailToken[2]" (keyup)="listenInputToken($event, 2)"
							(click)="clickInputToken(2);" maxlength="1" max="9" required [disabled]="_booleanInputOTP">
						<input type="number" class="InputToken" name="stringEmailToken4"
							[(ngModel)]="_arrayNumberEmailToken[3]" (keyup)="listenInputToken($event, 3)"
							(click)="clickInputToken(3);" maxlength="1" max="9" required [disabled]="_booleanInputOTP">
						<input type="number" class="InputToken" name="stringEmailToken5"
							[(ngModel)]="_arrayNumberEmailToken[4]" (keyup)="listenInputToken($event, 4)"
							(click)="clickInputToken(4);" maxlength="1" max="9" required [disabled]="_booleanInputOTP">
						<input type="number" class="InputToken" name="stringEmailToken6"
							[(ngModel)]="_arrayNumberEmailToken[5]" (keyup)="listenInputToken($event, 5)"
							(click)="clickInputToken(5);" maxlength="1" max="9" required [disabled]="_booleanInputOTP">
					</div>
				</fieldset>
			</div>
			<div class="DivButtonContainer">
				<input type="button" value="VERIFIKASI" (click)="goToSignIn()">
				<span>KIRIM ULANG OTP</span>
			</div>
		</form>
		<div class="DivAlignCenterContainer">
			<label>&nbsp;</label>
		</div>
	</section>


	<section class="SectionHeaderSignInContainer">
		<div class="DivHeaderContent">
			<h1>Agent<br>Management<br>System</h1>
			<label>
				Bergabunglah dengan BRI Insurance sebagai agen asuransi dan mulailah perjalanan karir yang penuh peluang
				dan kesuksesan. Di BRI Insurance, kami menawarkan benefit kompetitif, pelatihan profesional, dan
				dukungan penuh untuk membantu Anda mencapai potensi maksimal. Dengan berbagai produk asuransi terbaik
				yang dapat Anda tawarkan sekaligus mengembangkan karir Anda. Jangan lewatkan kesempatan ini untuk
				menjadi bagian dari Agen BRI Insurance yang dinamis dan inovatif.
			</label>

			<img id="imgIconRectangleOne" src="../../../assets/icons/sakina/icon_rectangle_one.svg"
				alt="Header - Icon Rectangle">
			<img id="imgIconRectangleTwo" src="../../../assets/icons/sakina/icon_rectangle_two.svg"
				alt="Header - Icon Rectangle">
			<img id="imgIconRectangleThree" src="../../../assets/icons/sakina/icon_rectangle_three.svg"
				alt="Header - Icon Rectangle">
		</div>
	</section>
</div>