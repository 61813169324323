//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { CustomerModel } from "../models/customer.model";
import { UserModel } from "../models/user.model";
import { AgencyDocumentModel } from "../models/agencydocument.model";
import { UserAgentModel } from "./../models/useragent.model";
// import { ResponseModel } from "../models/response.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class CustomerService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT

	selectCustomerByProfileID(interfaceGeneralService: GeneralServiceInterface, modelCustomer: CustomerModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelCustomer), WebAddressConstant.STRING_URL_CUSTOMER_SELECTCUSTOMERBYPROFILEID);
	}

	selectCustomerByPolicyNumber(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CUSTOMER_SELECTCUSTOMERBYPOLICYNUMBER);
	}

	selectCustomerByLicensePlate(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_CUSTOMER_SELECTCUSTOMERBYLICENSEPLATE);
	}

	selectCustomerByAttributes(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_CUSTOMER_SELECTCUSTOMERBYATTRIBUTES);
	}

	selectCustomerTicketByEmail(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_CUSTOMER_SELECTCUSTOMERTICKETBYEMAIL);
	}

	selectCustomerByEmail(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_CUSTOMER_SELECTCUSTOMERBYEMAIL);
	}

	//#endregion


	//#region BUSINESS

	verifyCustomerTicketByEmail(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_CUSTOMER_VERIFYCUSTOMERTICKETBYEMAIL);
	}

	verifyCustomerByEmail(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_CUSTOMER_VERIFYCUSTOMERBYEMAIL);
	}

	//#endregion


	//#region AMS


	selectAgentByEmailForSignin(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_AGENCYMANAGEMENTSYSTEM_SELECTAGENTBYEMAILFORSIGNIN);
	}

	selectAgentByEmail(interfaceGeneralService: GeneralServiceInterface, modelUser: UserAgentModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_AGENCYMANAGEMENTSYSTEM_SELECTAGENTBYEMAIL);
	}

	verifyAgentByEmail(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_AGENCYMANAGEMENTSYSTEM_VERIFYAGENTBYEMAIL);
	}

	insertUserForAgent(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_AGENCYMANAGEMENTSYSTEM_INSERTUSERFORAGENT);
	}

	updateUserForAgent(interfaceGeneralService: GeneralServiceInterface, modelUser: UserAgentModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_AGENCYMANAGEMENTSYSTEM_UPDATEUSERFORAGENT);
	}

	insertSupportingDocumentForAgent(interfaceGeneralService: GeneralServiceInterface, modelAgency: AgencyDocumentModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelAgency), WebAddressConstant.STRING_URL_AGENCYMANAGEMENTSYSTEM_INSERTSUPPORTINGDOCUMENTFORAGENT);
	}

	updateSupportingDocumentForAgent(interfaceGeneralService: GeneralServiceInterface, modelAgency: AgencyDocumentModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelAgency), WebAddressConstant.STRING_URL_AGENCYMANAGEMENTSYSTEM_UPDATESUPPORTINGDOCUMENTFORAGENT);
	}

	downloadSupportingDocumentForAgent(interfaceGeneralService: GeneralServiceInterface, modelAgency: AgencyDocumentModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelAgency), WebAddressConstant.STRING_URL_AGENCYMANAGEMENTSYSTEM_DOWNLOADSUPPORTINGDOCUMENTFORAGENT);
	}

	selectAgentByUserID(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_AGENCYMANAGEMENTSYSTEM_SELECTAGENTBYUSERID);
	}

	selectNotificationAgentByUserID(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_AGENCYMANAGEMENTSYSTEM_SELECTNOTIFICATIONAGENTBYUSERID);
	}

	selectIsNotificationExistByUserID(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_AGENCYMANAGEMENTSYSTEM_SELECTISNOTIFICATIONEXISTBYUSERID);
	}

	//#endregion


	//#region REGISTER

	registerCustomerByEmail(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_CUSTOMER_REGISTERCUSTOMERBYEMAIL);
	}

	//#endregion


	//#region SIGN OUT

	signOut(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_AGENCYMANAGEMENT_SIGNOUT);
	}

	//#endregion
}

//#endregion