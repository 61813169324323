//#region IMPORT

import { Pipe, PipeTransform } from "@angular/core";
import { StringConstant } from "src/app/constants/string.constant";
import { environment } from "src/environments/environment";

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertToProductURL"
	}
)

//#endregion


//#region CLASS

export class ConvertToProductURL implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue: string): string
	{
		if (stringValue === undefined || stringValue == null)
		{
			return "";
		}
		else
		{
			if (stringValue !== "")
			{
				return stringValue.replace("../..", environment.URL_PRODUCT_API);
			}
			else
			{
				return "";
			}
		}
	}

	transformShortDot(stringValue: string): string
	{
		if (stringValue === undefined || stringValue == null)
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
		else
		{
			if (stringValue !== "")
			{
				return stringValue.replace("./", environment.URL_PRODUCT_API + "/");
			}
			else
			{
				return StringConstant.STRING_CHARACTER_DASH;
			}
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertToUserURL"
	}
)

//#endregion


//#region CLASS

export class ConvertToUserURL implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: string): string
	{
		if (stringValue === undefined || stringValue == null)
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
		else
		{
			if (stringValue !== "")
			{
				return stringValue.replace("../..", environment.URL_USER_API);
			}
			else
			{
				return StringConstant.STRING_CHARACTER_DASH;
			}
		}
	}

	//#endregion
}

//#endregion



//#region PIPE

@Pipe
(
	{
		name: "ConvertToClaimURL"
	}
)

//#endregion


//#region CLASS

export class ConvertToClaimURL implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: string): string
	{
		if (stringValue === undefined || stringValue == null)
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
		else
		{
			if (stringValue !== "")
			{
				return stringValue.replace("../..", environment.URL_CLAIM_API);
			}
			else
			{
				return StringConstant.STRING_CHARACTER_DASH;
			}
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "ConvertToPolicyURL"
	}
)

//#endregion


//#region CLASS

export class ConvertToPolicyURL implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: string): string
	{
		if (stringValue === undefined || stringValue == null)
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
		else
		{
			if (stringValue !== "")
			{
				return stringValue.replace("../..", environment.URL_POLICY_API);
			}
			else
			{
				return StringConstant.STRING_CHARACTER_DASH;
			}
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "ConvertToBroadcastURL"
	}
)

//#endregion


//#region CLASS

export class ConvertToBroadcastURL implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: string): string
	{
		if (stringValue === undefined || stringValue == null)
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
		else
		{
			if (stringValue !== "")
			{
				return stringValue.replace("../..", environment.URL_BROADCAST_API);
			}
			else
			{
				return StringConstant.STRING_CHARACTER_DASH;
			}
		}
	}

	//#endregion
}

//#endregion



//#region PIPE

@Pipe
(
	{
		name: "convertToShortProductURL"
	}
)

//#endregion


//#region CLASS

export class ConvertToShortProductURL implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue: string): string
	{
		if (stringValue === undefined || stringValue == null)
		{
			return "";
		}
		else
		{
			if (stringValue !== "")
			{
				return stringValue.replace("../..", environment.URL_PRODUCT_API);
			}
			else
			{
				return "";
			}
		}
	}

	//#endregion
}

//#endregion
