import { ResponseModel } from "./response.model";
import { RuleConstant } from "./../constants/rule.constant";
import { ENUM_GENDER_TYPE, ENUM_MARITAL_STATUS, ENUM_RELIGION_TYPE, ENUM_RESPONSE_STATE, ENUM_AGENT_REGISTERSTATUS } from "./../constants/enum.constant";
import { StringConstant } from "./../constants/string.constant";
import { BranchModel } from "./branch.model";
import { DivisionModel } from "./division.model";
import { BaseModel } from "./bases/base.model";


export class UserAgentModel extends BaseModel
{
	FullName?: string;
	BirthDate?: Date;
	BirthPlace?: string;
	MaritalStatus?: ENUM_MARITAL_STATUS;
	BranchID?: any;
	Address?: string;
	TaxNumber?: string;
	ReligionType?: ENUM_RELIGION_TYPE;
	BRIAccountNumber?: string;
	MothersMaidenName?: string;
	HasCertificate?: boolean;
	AgentType?: number;
	PhoneNumber?: string;
	Email?: string;
	IdentificationNumber?: string;
	modelDivision?: DivisionModel;
	modelBranch?: BranchModel;
	CertificateExpirationDate?: Date;
	RegisterStatus?: ENUM_AGENT_REGISTERSTATUS;
	AgentToken?: string;
	CompanyName?: string;
	GenderType?: ENUM_GENDER_TYPE;


	constructor()
	{
		super();
		this.modelDivision = new DivisionModel();
		this.modelBranch = new BranchModel();
		this.setModelDivison();
		this.setCustomerCoreID();
	}


	setCustomerCoreID(): void
	{
		this.CustomerCoreID = this.ProfileID;
	}

	setModelDivison(): void
	{
		const stringModelDivision: string = JSON.stringify(this.modelDivision);
		this.modelDivision = new DivisionModel();
		this.modelDivision.setModelFromString(stringModelDivision);
	}

	getReligionTypeName(): string
	{
		if (this.Religion != null && this.Religion !== undefined)
		{
			if (this.Religion === ENUM_RELIGION_TYPE.Islam)
			{
				return "Islam";
			}
			else if (this.Religion === ENUM_RELIGION_TYPE.Christianity)
			{
				return "Christainity";
			}
			else if (this.Religion === ENUM_RELIGION_TYPE.Catholicism)
			{
				return "Chatoliism";
			}
			else if (this.Religion === ENUM_RELIGION_TYPE.Buddhism)
			{
				return "Buddhism";
			}
			else if (this.Religion === ENUM_RELIGION_TYPE.Hinduism)
			{
				return "Hinduism";
			}
			else if (this.Religion === ENUM_RELIGION_TYPE.Confucianism)
			{
				return "Confucianism";
			}
			else
			{
				return StringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
	}

	getMaritalTypeName(): string
	{
		if (this.MaritalStatus != null && this.MaritalStatus !== undefined)
		{
			if (this.MaritalStatus === ENUM_MARITAL_STATUS.Single)
			{
				return "Single";
			}
			else if (this.MaritalStatus === ENUM_MARITAL_STATUS.Married)
			{
				return "Married";
			}
			else if (this.MaritalStatus === ENUM_MARITAL_STATUS.Divorce)
			{
				return "Divorce";
			}
			else if (this.MaritalStatus === ENUM_MARITAL_STATUS.Widow)
			{
				return "Widow";
			}
			else
			{
				return StringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
	}


	//#region VALIDATION

	validateEmail(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("User");

		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAILWITHOUTEMOJI);

		if (this.Email == null || this.Email === undefined || this.Email === "")
		{
			modelResponse.MessageContent = "Email tidak boleh kosong";
		}
		else if (regularExpression.test(this.Email))
		{
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		else
		{
			modelResponse.MessageContent = "Masukkan email yang valid.";
		}

		return modelResponse;
	}

	validateEmailToken(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("User");

		if (this.EmailToken == null || this.EmailToken === undefined || this.EmailToken === "")
		{
			modelResponse.MessageContent = "OTP Email can't be empty.";
		}
		else if (this.EmailToken.length !== RuleConstant.NUMBER_EMAILTOKEN_LENGTH)
		{
			modelResponse.MessageContent = "OTP Email should be " + RuleConstant.NUMBER_EMAILTOKEN_LENGTH + " characters length.";
		}
		else
		{
			modelResponse.MessageContent = "Formulir diisi dengan benar.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}
		return modelResponse;
	}

	validateFormUser(): ResponseModel
	{
		const regExNumeric: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_NUMERIC);
		// const regExAlphabet: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABET);
		const regExAlphaNumericForAddress: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERICFORADDRESS);

		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("User");

		this.validateEmail();

		if (this.FullName == null || this.FullName === undefined || this.FullName === "")
		{
			modelResponse.MessageContent = "Nama tidak boleh kosong.";
		}
		// else if (this.MiddleName !== undefined && (!regExAlphabet.test(this.MiddleName)))
		// {
		// 	modelResponse.MessageContent = "Format input Nama Tengah mengandung karakter yang tidak diizinkan.";
		// 	this.MiddleName = undefined;
		// }
		// else if (this.LastName !== undefined && (!regExAlphabet.test(this.LastName)))
		// {
		// 	modelResponse.MessageContent = "Format input Nama Belakang mengandung karakter yang tidak diizinkan.";
		// 	this.LastName = undefined;
		// }
		else if (this.BirthPlace == null || this.BirthPlace === undefined || this.BirthPlace === "")
		{
			modelResponse.MessageContent = "Tempat lahir tidak boleh kosong.";
		}
		else if (this.BirthDate == null || this.BirthDate === undefined)
		{
			modelResponse.MessageContent = "Tanggal lahir tidak boleh kosong.";
		}
		else if (this.IdentificationNumber == null || this.IdentificationNumber === undefined || this.IdentificationNumber === "")
		{
			modelResponse.MessageContent = "KTP tidak boleh kosong.";
		}
		else if (!regExNumeric.test(this.IdentificationNumber))
		{
			modelResponse.MessageContent = "KTP hanya boleh angka.";
		}
		else if ((this.IdentificationNumber.length < RuleConstant.NUMBER_IDENTIFICATIONNUMBER_KTP_LENGTH))
		{
			modelResponse.MessageContent = "KTP minimal " + RuleConstant.NUMBER_IDENTIFICATIONNUMBER_KTP_LENGTH + " karakter.";
		}
		else if ((this.IdentificationNumber.length > RuleConstant.NUMBER_IDENTIFICATIONNUMBER_KTP_LENGTH))
		{
			modelResponse.MessageContent = "KTP maksimal " + RuleConstant.NUMBER_IDENTIFICATIONNUMBER_KTP_LENGTH + " karakter.";
		}
		else if (this.BranchID == null || this.BranchID === undefined)
		{
			modelResponse.MessageContent = "Cabang tidak boleh kosong.";
		}
		else if (this.PhoneNumber == null || this.PhoneNumber === undefined || this.PhoneNumber === "")
		{
			modelResponse.MessageContent = "Nomor handphone tidak boleh kosong.";
		}
		else if (!regExNumeric.test(this.PhoneNumber))
		{
			modelResponse.MessageContent = "Nomor Handphone hanya boleh angka.";
		}
		else if (this.TaxNumber == null || this.TaxNumber === undefined || this.TaxNumber === "")
		{
			modelResponse.MessageContent = "NPWP tidak boleh kosong.";
		}
		else if (this.MaritalStatus == null || this.MaritalStatus === undefined)
		{
			modelResponse.MessageContent = "Status Pernikahan tidak boleh kosong.";
		}
		else if (this.ReligionType == null || this.ReligionType === undefined)
		{
			modelResponse.MessageContent = "Status Agama tidak boleh kosong.";
		}
		else if (this.BRIAccountNumber == null || this.BRIAccountNumber === undefined)
		{
			modelResponse.MessageContent = "Nomor Rekening BRI tidak boleh kosong.";
		}
		else if (this.Address == null || this.Address === undefined || this.Address === "")
		{
			modelResponse.MessageContent = "Address tidak boleh kosong.";
		}
		else if (this.MothersMaidenName == null || this.MothersMaidenName === undefined)
		{
			modelResponse.MessageContent = "Nama Gadis Ibu Kandung tidak boleh kosong.";
		}
		else if (!regExAlphaNumericForAddress.test(this.Address))
		{
			modelResponse.MessageContent = "Format input Alamat mengandung karakter yang tidak diizinkan.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	//endregion
}

