//#region IMPORT

import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BranchService } from "src/app/services/branch.service";
import { CustomerService } from "src/app/services/customer.service";
import { SessionService } from "src/app/services/session.service";
import { NumberConstant } from "src/app/constants/number.constant";
import { ENUM_GENDER_TYPE, ENUM_DOCUMENT_TYPE, ENUM_MARITAL_STATUS, ENUM_RELIGION_TYPE, ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { UserModel } from "src/app/models/user.model";
import { ResponseModel } from "src/app/models/response.model";
import { UserSessionModel } from "src/app/models/usersession.model";
import { RuleConstant } from "src/app/constants/rule.constant";
import { AgencyDocumentModel } from "src/app/models/agencydocument.model";
import { SupportingDocumentModel } from "src/app/models/supportingdocument.model";
import { HandshakeModel } from "src/app/models/handshake.model";
import { BranchModel } from "src/app/models/branch.model";
import { BaseAuthourizeDetailComponent } from "../bases/baseauthourizedetail.component";
import { UserAgentModel } from "./../../models/useragent.model";
import { TableModel } from "./../../models/bases/table.model";

//#endregion


//#region COMPONENT

@Component({
	selector: "app-editprofile",
	templateUrl: "./editprofile.component.html",
	styleUrls: ["./editprofile.component.sass"]
})

//#endregion


//#region CLASS
export class EditprofileComponent extends BaseAuthourizeDetailComponent implements OnInit
{
	//#region DECLARATION

	public _numberStepperActive: number;

	public _booleanShow: boolean;
	public _booleanRole: any;
	public _booleanCertificate: boolean;
	public _booleanGender: any;


	public _modelUserPublic: UserModel;
	public _modelUserAgent: UserAgentModel;
	public _modelUserDocument: AgencyDocumentModel;
	public _modelUserSupportingDocument: SupportingDocumentModel;
	public _modelHandshake: HandshakeModel;
	public _modelUndefined: undefined;
	public _modelBranchResponse: BranchModel;
	public _modelTable: TableModel;

	public _stringFileTypeAllowed: string;
	public _stringConstant = StringConstant;

	public _enumMaritalType = ENUM_MARITAL_STATUS;
	public _enumReligion = ENUM_RELIGION_TYPE;
	public _enumGenderType = ENUM_GENDER_TYPE;

	public _arrayBranch: any = [];
	public _arrayCertificate: any = [];
	public _arraySupportingDocument: any = [];
	public _arrayEPKS: any = [];


	//#endregion


	//#region CONSTRUCTOR

	constructor(private _serviceCustomer: CustomerService, routeActivated: ActivatedRoute, location: Location, serviceSession: SessionService, router: Router, private _serviceBranch: BranchService)
	{
		super(routeActivated, location, serviceSession, router);

		this._booleanShow = false;
		this._booleanCertificate = false;

		this._numberStepperActive = NumberConstant.NUMBER_COMPARE_VALUE_ONE;

		this._modelUserPublic = new UserModel();
		this._modelUserAgent = new UserAgentModel();
		this._modelUserDocument = new AgencyDocumentModel();
		this._modelUserSupportingDocument = new SupportingDocumentModel();
		this._modelHandshake = new HandshakeModel();
		this._modelBranchResponse = new BranchModel();
		this._modelTable = new TableModel();

		this._stringFileTypeAllowed = RuleConstant.STRING_FILE_TYPE_ALLOWED;

	}

	//#endregion


	//#region INITIALIZE

	ngOnInit(): void
	{
		// this.setInitialListModelAgentSupportingDocument();
		this.callSelectAgentByUserID(this);
		this.callSelectBranchForAgent(this);
	}

	//#endregion


	//#region WEB SERVICE

	private callSelectBranchForAgent(componentCurrent: EditprofileComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		const modelBranch: BranchModel = new BranchModel();
		modelBranch.ID = this._modelUserPublic.BranchID;

		componentCurrent._modelTable.Page = 1;
		componentCurrent._modelTable.RowPerPage = 99;

		this._serviceBranch.selectBranchForAgent
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const arrayBranchResponse: BranchModel = new BranchModel();
						arrayBranchResponse.setModelFromString(modelResponse.Data);
						let modelBranchTemp: any = [];
						modelBranchTemp = JSON.parse(arrayBranchResponse.Result);
						const arrayBranchTemp: any = [];
						arrayBranchTemp.push(modelBranchTemp);
						componentCurrent._arrayBranch = arrayBranchTemp[0];
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectBranchForAgent(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelTable);
	}

	callUpdate()
	{
		try
		{
		  this.callUpdateUserForAgent(this);
		}
		catch (error)
		{
		  console.error("Error updating data:", error);
		}
	  }

	callUpdateUserForAgent(componentCurrent: EditprofileComponent): void
	{
		this._modelUserAgent.PhoneNumber = this._modelUserPublic.PhoneNumber?.toString();
		this._modelUserAgent.AgentType = this._booleanRole;
		this._modelUserAgent.HasCertificate = this._booleanCertificate;
		this._modelUserAgent.FullName = this._modelUserPublic.FullName;
		this._modelUserAgent.BirthDate = this._modelUserPublic.BirthDate;
		this._modelUserAgent.Email = this._modelUserPublic.Email;
		this._modelUserAgent.MaritalStatus = this._modelUserPublic.MaritalStatus;
		this._modelUserAgent.ReligionType = this._modelUserPublic.ReligionType;
		this._modelUserAgent.Address = this._modelUserPublic.Address;
		this._modelUserAgent.IdentificationNumber = this._modelUserPublic.IdentificationNumber;
		this._modelUserAgent.modelDivision = this._modelUserPublic.modelDivision;
		this._modelUserAgent.modelBranch = this._modelUserPublic.modelBranch;
		this._modelUserAgent.CertificateExpirationDate = this._modelUserPublic.CertificateExpirationDate;
		this._modelUserAgent.MothersMaidenName = this._modelUserPublic.MothersMaidenName;
		this._modelUserAgent.BRIAccountNumber = this._modelUserPublic.AccountNumber;
		this._modelUserAgent.BirthPlace = this._modelUserPublic.BirthPlace;
		this._modelUserAgent.BranchID = Number(this._modelUserPublic.BranchID);
		this._modelUserAgent.TaxNumber = this._modelUserPublic.TaxNumber;
		this._modelUserAgent.CompanyName = this._modelUserPublic.CompanyName;
		this._modelUserAgent.Gender = this._booleanGender;
		delete this._modelUserAgent.modelDivision;
		delete this._modelUserAgent.modelBranch;
		componentCurrent._functionUserInterface.setLoadingProgress(1);
		let modelResponseValidation: ResponseModel = this._modelUserAgent.validateFormUser();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponseValidation = this._modelUserAgent.validateFormUser();
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			this._serviceCustomer.updateUserForAgent
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._functionUserInterface.updateLoadingProgress();

							const modelUserResponse: UserModel = new UserModel();
							modelUserResponse.setModelFromString(modelResponse.Data);

							componentCurrent._serviceSession.setModelUserSignIn(modelUserResponse);
							componentCurrent._booleanShow = true;
							componentCurrent._numberStepperActive = NumberConstant.NUMBER_COMPARE_VALUE_TWO;
							componentCurrent.setEventEmitterNumber(componentCurrent._numberStepperActive);
							// componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });


							const modelUserSession = new UserSessionModel();
							modelUserSession.UserToken = modelUserResponse.Token;
							componentCurrent._modelUserDocument.AgentToken = modelUserResponse.Token;

							componentCurrent._serviceSession.setUserSession(modelUserSession);
							componentCurrent.callUpdateSupportingDocumentForAgent(componentCurrent);

						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				}
			}, this._modelUserAgent);
		}
	}

	callUpdateSupportingDocumentForAgent(componentCurrent: EditprofileComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelUserDocument.AgentToken = componentCurrent._modelUserPublic.modelAgent?.Token;
		componentCurrent._modelUserDocument.listModelAgentSupportingDocument = this._modelUserDocument.listModelAgentDocumentModel;
		componentCurrent._modelUserDocument.CertificateExpirationDate = this._modelUserPublic.CertificateExpirationDate;

		const modelResponseRequest: ResponseModel = componentCurrent._modelUserDocument.validateDocument();
		if(modelResponseRequest.State === ENUM_RESPONSE_STATE.Success)
		{
			componentCurrent._modelUserDocument.listModelAgentSupportingDocument = this.removeEmptyObjects(componentCurrent._modelUserDocument.listModelAgentSupportingDocument);
			componentCurrent._serviceCustomer.updateSupportingDocumentForAgent
			({
				success(modelResponse: ResponseModel): void
				{
					modelResponse.MessageTitle = "Supporting Document Submit";

					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						componentCurrent._functionUserInterface.updateLoadingProgress();
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent._router.navigate(["home/profile"]); });
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					modelResponse.MessageTitle = "Supporting Document Submit Failed";

					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callUpdateSupportingDocumentForAgent(componentCurrent); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					modelResponse.MessageTitle = "Supporting Document Submit Failed";

					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelUserDocument);
		}

	}

	callSelectAgentByUserID(componentCurrent: this): void
	{
		this._modelHandshake.UserID = this._modelUserSignIn.UserID;

		componentCurrent._functionUserInterface.setLoadingProgress(1);

		this._serviceCustomer.selectAgentByUserID
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelUserPublic.setModelFromString(modelResponse.Data);
						componentCurrent._functionUserInterface.updateLoadingProgress();

						componentCurrent._modelUserDocument.setModelFromString(JSON.stringify(componentCurrent._modelUserPublic.modelAgent));

						componentCurrent._modelUserPublic.CertificateExpirationDate = componentCurrent._modelUserPublic.modelAgent?.CertificateExpiration;
						componentCurrent._modelUserPublic.CompanyName = componentCurrent._modelUserPublic.modelAgent?.CompanyName;
						componentCurrent._modelUserPublic.BranchID = componentCurrent._modelUserPublic.modelBranch?.ID;
						componentCurrent._modelUserPublic.ReligionType = componentCurrent._modelUserPublic.Religion;
						componentCurrent._modelUserPublic.PhoneNumber = componentCurrent._modelUserPublic.MobilePhone;
						componentCurrent._modelUserPublic.MothersMaidenName = componentCurrent._modelUserPublic.MothersMaidenName;
						componentCurrent._modelUserPublic.BRIAccountNumber = componentCurrent._modelUserPublic.AccountNumber;
						componentCurrent._booleanGender = componentCurrent._modelUserPublic.Gender;


						const arrayModelSupportingDocument: Array<SupportingDocumentModel> = componentCurrent._modelUserDocument.listModelAgentDocumentModel || [];
						componentCurrent._modelUserDocument.listModelAgentDocumentModel = [];

						arrayModelSupportingDocument?.forEach(modelAgentDocumentModelLoop =>
						{
							const modelSupportingDocument: SupportingDocumentModel = new SupportingDocumentModel();
							const stringSupportingDocument: string = JSON.stringify(modelAgentDocumentModelLoop);

							modelSupportingDocument.setModelFromString(stringSupportingDocument);

							componentCurrent._modelUserDocument.listModelAgentDocumentModel?.push(modelSupportingDocument);
						});

						if(componentCurrent._modelUserPublic.modelAgent?.CertificateExpiration)
						{
							componentCurrent._booleanCertificate = true;
						}
						else
						{
							componentCurrent._booleanCertificate = false;
						}

						if(componentCurrent._modelUserPublic.modelBranch !== undefined)
						{
							componentCurrent._modelBranchResponse = componentCurrent._modelUserPublic.modelBranch;
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.callSelectAgentByUserID(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
			}
		}, this._modelUserPublic);
	}

	//#endregion


	//#region GETTER


	//#endregion


	//#region LISTENER


	//#endregion

	//#region NAVIGATION

	goToOTPEmail(): void
	{
		this._booleanShow = false;
	}

	//#endregion


	//#region FUNCTION

	onDateChange(event: any): void
	{
		const tempDate = event.value;
		// Setting time to noon to avoid time zone issues
		tempDate.setHours(12, 0, 0, 0);
		this._modelUserPublic.CertificateExpirationDate = tempDate;
	}

	removeEmptyObjects(arr: any): any
	{
		return arr.filter(obj => Object.keys(obj).length >= 1 && obj.Name?.length >0 );
	}

	setEventEmitterNumber(numberIndex: number): void
	{
		this._numberStepperActive = numberIndex;
	}



	listenFileUpload(event, numType: ENUM_DOCUMENT_TYPE): void
	{
		const arrayFileUpload: Array<File> = event.target.files;
		if(arrayFileUpload != null && arrayFileUpload !== undefined && arrayFileUpload.length > 0)
		{
			let modelSupportingDocument: SupportingDocumentModel;
			for (const filesUpload of arrayFileUpload)
			{
				filesUpload.convertToBase64(
					(stringBase64) =>
					{
						// this._modelUserDocument.listModelAgentSupportingDocument = [];

						modelSupportingDocument = new SupportingDocumentModel();
						modelSupportingDocument.Format = filesUpload.type;
						modelSupportingDocument.Size = filesUpload.size;
						modelSupportingDocument.Name = filesUpload.name;
						modelSupportingDocument.Data = stringBase64;
						modelSupportingDocument.Extension = filesUpload.name.getFileExtension();
						modelSupportingDocument.DocumentType = numType;

						const modelResponse: ResponseModel = modelSupportingDocument.validateDocument();

						if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
						{
							if(this._modelUserDocument.listModelAgentDocumentModel)
							{
								for(let numberIndex = 0; this._modelUserDocument.listModelAgentDocumentModel.length; numberIndex++)
								{
									if (this._modelUserDocument.listModelAgentDocumentModel[numberIndex].DocumentType === modelSupportingDocument.DocumentType)
									{
										this._modelUserDocument.listModelAgentDocumentModel[numberIndex] = modelSupportingDocument;
									}
								}
							}


						}
						else
						{
							this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					},
					() =>
					{

					}
				);
			}
		}
	}


	public goToList()
	{
		this._router.navigate(["home"]);
	}

	//#endregion


	//#region SETTER

	private setInitialListModelAgentSupportingDocument(): void
	{
		this._modelUserDocument.listModelAgentSupportingDocument?.push(new SupportingDocumentModel());
	}

	//#endregion
}

//#endregion
