import { AgencyDocumentModel } from "src/app/models/agencydocument.model";
/* eslint-disable max-len */
//#region IMPORT

import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ENUM_GENDER_TYPE } from "src/app/constants/enum.constant";
import { NumberConstant } from "src/app/constants/number.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { RuleConstant } from "src/app/constants/rule.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { ConvertToBroadcastURL, ConvertToUserURL } from "src/app/functions/pipes/hyperlink.pipe";
import { RegexFunction } from "src/app/functions/regex.function";
import { TableModel } from "src/app/models/bases/table.model";
import { BranchModel } from "src/app/models/branch.model";
import { CustomerModel } from "src/app/models/customer.model";
import { ResponseModel } from "src/app/models/response.model";
import { UserModel } from "src/app/models/user.model";
import { BranchService } from "src/app/services/branch.service";
import { CustomerService } from "src/app/services/customer.service";
import { SessionService } from "src/app/services/session.service";
import { HandshakeModel } from "src/app/models/handshake.model";
import { SupportingDocumentModel } from "src/app/models/supportingdocument.model";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";
import { ENUM_RESPONSE_STATE } from "./../../constants/enum.constant";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-profile",
		templateUrl: "./profile.component.html",
		styleUrls: ["./profile.component.sass"],
	}
)

//#endregion


//#region CLASS

export class ProfileComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _pipeConvertToBroadcastURL = new ConvertToBroadcastURL();

	public _modelUserPublic: UserModel;
	public _modelUserPublicUpload: UserModel;
	public _modelUserPublicRequest: UserModel;
	public _modelUserPublicResponse: UserModel;
	public _modelCustomer: CustomerModel;
	public _modelBranch: BranchModel;
	public _modelBranchResponse: BranchModel;
	public _modelTable: TableModel;
	public _modelHandshake: HandshakeModel;
	public _modelUserDocument: AgencyDocumentModel;

	public _booleanPostalCodeValid: boolean;

	public _numberConstant = NumberConstant;

	public _enumStringConstant = StringConstant;
	public _enumGenderType = ENUM_GENDER_TYPE;

	public _arrayModelBranch: Array<BranchModel>;
	public _arrayGalleryModel: Array<number>;

	public _dateNow: Date;

	public _stringImageUrl: string | ArrayBuffer;
	public _stringFileTypeAllowed: string = RuleConstant.STRING_FILE_TYPE_ALLOWED;
	public _stringConstant = StringConstant;

	public _fileToUpload: any;

	public _pipeConvertToUserURL: ConvertToUserURL;

	public _functionRegex: RegexFunction;

	//#endregion


	//#region CONSTRUCTUR

	constructor(private _serviceCustomer: CustomerService, location: Location, serviceSession: SessionService, router: Router, private _serviceBranch: BranchService)
	{
		super(serviceSession, router);

		this._modelUserPublic = new UserModel();
		this._modelUserPublicUpload = new UserModel();
		this._modelUserPublicRequest = new UserModel();
		this._modelUserPublicResponse = new UserModel();
		this._modelCustomer = new CustomerModel();
		this._modelBranch = new BranchModel();
		this._modelBranchResponse = new BranchModel();
		this._modelTable = new TableModel();
		this._modelHandshake = new HandshakeModel();
		this._modelUserDocument = new AgencyDocumentModel();

		this._arrayModelBranch = [];
		this._arrayGalleryModel = [];
		this._dateNow = new Date();

		this._booleanPostalCodeValid = false;

		this._stringImageUrl = "";

		this._pipeConvertToUserURL = new ConvertToUserURL();
		this._functionRegex = new RegexFunction();
	}

	//#endregion


	//#region ONINIT

	ngOnInit(): void
	{
		this.callSelectAgentByUserID(this);
	}

	//#endregion


	//#region WEB SERVICE

	callSelectAgentByUserID(componentCurrent: this): void
	{
		this._modelHandshake.UserID = this._modelUserSignIn.UserID;

		componentCurrent._functionUserInterface.setLoadingProgress(1);

		this._serviceCustomer.selectAgentByUserID
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelUserPublic.setModelFromString(modelResponse.Data);
						componentCurrent._functionUserInterface.updateLoadingProgress();
						componentCurrent._modelUserDocument.setModelFromString(JSON.stringify(componentCurrent._modelUserPublic.modelAgent));

						const arrayModelSupportingDocument: Array<SupportingDocumentModel> = componentCurrent._modelUserDocument.listModelAgentDocumentModel || [];
						componentCurrent._modelUserDocument.listModelAgentDocumentModel = [];

						arrayModelSupportingDocument?.forEach(modelAgentDocumentModelLoop =>
						{
							const modelSupportingDocument: SupportingDocumentModel = new SupportingDocumentModel();
							const stringSupportingDocument: string = JSON.stringify(modelAgentDocumentModelLoop);

							modelSupportingDocument.setModelFromString(stringSupportingDocument);

							componentCurrent._modelUserDocument.listModelAgentDocumentModel?.push(modelSupportingDocument);
						});

						if(componentCurrent._modelUserPublic.modelBranch !== undefined)
						{
							componentCurrent._modelBranchResponse = componentCurrent._modelUserPublic.modelBranch;
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.callSelectAgentByUserID(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
			}
		}, this._modelUserPublic);
	}

	public callSelectBranchForCustomer(): void
	{
		const componentCurrent: ProfileComponent = this;

		componentCurrent._modelTable.Search = JSON.stringify(this._modelUserPublic.BranchName);
		componentCurrent._functionUserInterface.setLoadingProgress(1);
		this._modelTable.RowPerPage = 100;

		this._serviceBranch.selectBranchForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTable.setModelFromString(modelResponse.Data);

						if (componentCurrent._modelTable.Result !== undefined)
						{
							componentCurrent._arrayModelBranch = JSON.parse(componentCurrent._modelTable.Result);
							componentCurrent._modelTable.Result = undefined;
						}
						else
						{

						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent._functionUserInterface.updateLoadingProgress();
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelTable);
	}

	callDownloadSupportingDocumentForAgent(componentCurrent: ProfileComponent, numberIndex: number): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		const modelUserAgentAttachment: SupportingDocumentModel = new SupportingDocumentModel();

		if (this._modelUserPublic.modelAgent?.listModelAgentDocumentModel)
		{
			const modelAgentDocument = this._modelUserPublic.modelAgent?.listModelAgentDocumentModel[numberIndex];

			modelUserAgentAttachment.Token = modelAgentDocument.Token;
		}

		let modelResponseValidation: ResponseModel = new ResponseModel();
		modelResponseValidation = modelUserAgentAttachment.validateDocumentToken();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			this._serviceCustomer.downloadSupportingDocumentForAgent
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.Data !== undefined)
					{
						const modelAgentAttachmentDownload: SupportingDocumentModel = new SupportingDocumentModel();
						modelAgentAttachmentDownload.setModelFromString(modelResponse.Data);

						if (modelAgentAttachmentDownload !== undefined)
						{
							componentCurrent._functionUserInterface.updateLoadingProgress();
							if (modelAgentAttachmentDownload.Name == null || modelAgentAttachmentDownload.Name === undefined || modelAgentAttachmentDownload.Format == null || modelAgentAttachmentDownload.Format === undefined || modelAgentAttachmentDownload.Extension == null || modelAgentAttachmentDownload.Extension === undefined || modelAgentAttachmentDownload.Data == null || modelAgentAttachmentDownload.Data === undefined)
							{

							}
							else
							{
								componentCurrent._functionUserInterface.downloadFileBlob(modelAgentAttachmentDownload.Name, modelAgentAttachmentDownload.Format, modelAgentAttachmentDownload.Extension, modelAgentAttachmentDownload.Data);
							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callDownloadSupportingDocumentForAgent(componentCurrent, numberIndex); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, modelUserAgentAttachment);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => {});
		}
	}

	//#endregion


	//#region AFTERVIEWINIT

	ngAfterViewInit(): void
	{
		this.setViewFromTop();
	}

	//#endregion


	//#region FUNCTION

	getImageFromBroadcastURL(stringURL: string | undefined): string | null
	{
		if(stringURL === undefined)
		{
			return "-";
		}
		else
		{
			return this._pipeConvertToBroadcastURL.transform(stringURL);
		}
	}

	protected setViewFromTop(): void
	{
		let top = document.getElementById("divMainDashboard");
		if (top !== null)
		{
			top.scrollIntoView();
			top = null;
		}
	}


	deletePhotoURL()
	{
		if (this._modelUserPublic.listModelUserGallery)
		{
			for (const modelUserPublic of this._modelUserPublic.listModelUserGallery)
			{
				if (modelUserPublic.State === 0)
				{
					modelUserPublic.Data = undefined;
				}
				else
				{

				}
			}
		}
	}

	//#endregion


	//#region NAVIGATION

	goToEditEmail(stringToken?: string | null): void
	{
		if (stringToken == null || stringToken === undefined || stringToken === "")
		{
			this._router.navigate(["home"]);
		}
		else
		{
			this._router.navigate(["editemail", stringToken]);
		}
	}

	goToEditProfile(): void
	{
		this._router.navigate(["editprofile"]);
	}

	goToEditMobilePhone(stringToken?: string | null): void
	{
		if (stringToken == null || stringToken === undefined || stringToken === "")
		{
			this._router.navigate(["home"]);
		}
		else
		{
			this._router.navigate(["editmobilephone", stringToken]);
		}
	}

	//#endregion


	//#region LISTENER

	// selectPostalCode(): void
	// {
	// 	if(this._modelUserPublic.PostalCode?.length === this._numberConstant.NUMBER_COMPARE_VALUE_FIVE)
	// 	{
	// 		this.callSelectLocationByPostalCode();
	// 	}
	// 	else
	// 	{

	// 	}
	// }

	// listenFileUpload(event): void
	// {
	// 	const fileUploadFirstItem = event.target.files[0];
	// 	const reader = new FileReader();
	// 	// read file as data url
	// 	reader.readAsDataURL(event.target.files[0]);

	// 	reader.onload = (events) =>
	// 	{ // called once readAsDataURL is completed

	// 		this._stringImageUrl = events.target?.result as string;
	// 		this._modelUserPublicResponse.PhotoURL = this._stringImageUrl;
	// 		if (this._modelUserPublic.listModelUserGallery)
	// 		{
	// 			for (const modelUserPublic of this._modelUserPublic.listModelUserGallery)
	// 			{
	// 				modelUserPublic.Data = this._stringImageUrl;
	// 			}
	// 		}
	// 	};


	// 	const arrayFileUpload: Array<File> = event.target.files;

	// 	let modelResponse: ResponseModel = new ResponseModel();

	// 	if (arrayFileUpload != null && arrayFileUpload !== undefined && arrayFileUpload.length > 0)
	// 	{
	// 		let modelUserGallery: UserGalleryModel;

	// 		for (const fileUpload of arrayFileUpload)
	// 		{
	// 			fileUpload.convertToBase64(
	// 				(stringBase64) =>
	// 				{
	// 					if (this._modelUserPublicUpload.validateAddForUpload())
	// 					{
	// 						const arraySplitName: Array<any> = fileUploadFirstItem.name.split(".");

	// 						this._modelUserPublicUpload.listModelUserGallery = [];

	// 						modelUserGallery = new UserGalleryModel();
	// 						modelUserGallery.Format = fileUpload.type;
	// 						modelUserGallery.Size = fileUpload.size;
	// 						modelUserGallery.Name = arraySplitName[0];
	// 						modelUserGallery.Data = stringBase64;
	// 						modelUserGallery.Extension = fileUpload.name.getFileExtension();

	// 						modelResponse = modelUserGallery.validateAdd();

	// 						if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
	// 						{
	// 							this._modelUserPublicUpload?.listModelUserGallery?.push(modelUserGallery);
	// 						}
	// 						else
	// 						{
	// 							this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
	// 						}

	// 						modelUserGallery = new UserGalleryModel();
	// 					}
	// 					else
	// 					{
	// 					}
	// 				},
	// 				() =>
	// 				{

	// 				}
	// 			);
	// 		}
	// 	}
	// 	else
	// 	{
	// 	}
	// }

	//#endregion


	//#region GETTER

	getBirthDate(event): void
	{
		const maxDate: Date = this._dateNow;
		maxDate.setDate(maxDate.getDate() + 1);
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Tanggal Lahir";
		modelResponse.MessageContent = "Tanggal lahir tidak boleh lebih dari hari ini!";

		if(event.value > maxDate)
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
		}
		else
		{
			this._modelUserPublic.BirthDate = event.value.clearUTC();
		}
	};

	getImage(stringURL): string
	{
		return this._pipeConvertToUserURL.transform(stringURL);
	}

	//#endregion


	//#region SETTER

	setGender(enumGender: ENUM_GENDER_TYPE): void
	{
		if(enumGender !== undefined)
		{
			this._modelUserSignIn.Gender = enumGender;
			this._modelUserPublic.Gender = enumGender;
		}
	}

	setBranch(modelBranch: BranchModel | any): void
	{
		if(modelBranch !== undefined)
		{
			this._modelUserPublic.BranchID = modelBranch.ID;
			this._modelUserPublic.BranchCode = modelBranch.Code;
			this._modelUserPublic.BranchName = modelBranch.Name;

			this._modelBranch.ID = modelBranch.ID;
			this._modelBranch.Code = modelBranch.Code;
			this._modelBranch.Name = modelBranch.Name;

			this._modelUserPublic.modelBranch = this._modelBranch;
		}
	}

	setBooleanDataSharingConsent(event): void
	{
		this._modelUserPublic.DataSharingConsent = event.checked;
	}

	setBooleanProductPromotionConsent(event): void
	{
		this._modelUserPublic.ProductPromotionConsent = event.checked;
	}

	//#endregion

}
//#endregion
