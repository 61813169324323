//#region IMPORT

import { Component } from "@angular/core";
// import { SafeResourceUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
// import { ENUM_DEVICE_TYPE, ENUM_HEADER_TYPE, ENUM_PERIOD_TYPE } from "src/app/constants/enum.constant";
// import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
// import { StringConstant } from "src/app/constants/string.constant";
// import { LandingContentModel } from "src/app/models/landingcontent.model";
// import { ResponseModel } from "src/app/models/response.model";
import { LandingService } from "src/app/services/landing.service";
import { SessionService } from "src/app/services/session.service";
// import { LandingContentGalleryModel } from "src/app/models/landingcontentgallery.model";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-landing",
		templateUrl: "./landing.component.html",
		styleUrls: ["./landing.component.sass"],
	}
)

//#endregion


//#region  CLASS

export class LandingComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	// public _modelLandingContent: LandingContentModel;
	// public _modelLandingContentRequest: LandingContentModel;
	// public _modelHeaderContent: HeaderContentModel;
	// public _modelHeaderContentRequest: HeaderContentModel;

	public _stringImageMainHeader: string;
	public _stringImageMainHeaderMobile: string;
	// public _stringImageContentHeader: string;
	// public _stringConstant = StringConstant;

	// public _arrayModelProductAnnually: Array<ContentModel>;
	// public _arrayModelProductMonthly: Array<ContentModel>;
	// public _arrayModelProductDaily: Array<ContentModel>;
	// public _arrayModelProduct: Array<ContentModel>;
	// public _arrayModelProductAsmikAnnually: Array<ContentModel>;
	// public _arrayModelProductAsmik: Array<ContentModel>;
	// public _arraySocialMedia: Array<ContentModel>;
	// public _arrayModelLandingContent: Array<LandingContentModel>;
	// public _arrayModelLandingContentGallery: Array<LandingContentGalleryModel>;
	// public _arrayModelHeaderContentGallery: Array<HeaderContentGalleryModel>;

	// public _numberPriodeProduct: ENUM_PERIOD_TYPE;

	// public _enumPeriodeProduct = ENUM_PERIOD_TYPE;

	// public _booleanModalVideo: boolean;

	// public _stringURLAboutBRINS: string;
	// public _stringURLAboutBRINSEmbed: SafeResourceUrl;

	//#endregion


	//#region CONSTRUCTUR

	constructor(serviceSession: SessionService, router: Router, private _serviceLandingContent: LandingService)
	{
		super(serviceSession, router);

		this._stringImageMainHeader = "";
		this._stringImageMainHeaderMobile = "";

	}

	//#endregion


	//#region ONINIT

	ngOnInit(): void
	{

	}

	//#endregion


	//#region AFTERVIEWINIT

	ngAfterViewInit(): void
	{
		this.setViewFromTop();
	}

	//#endregion


	//#region WEB SERVICE


	//#endregion


	//#region FUNCTION

	getImageMain(): any
	{
		if(this.checkMinimumPixelForPhotoHeader())
		{
			return {"background-image": "url(" + this._stringImageMainHeaderMobile + ")"};
		}
		else
		{
			return {"background-image": "url(" + this._stringImageMainHeader + ")"};
		}
	}


	//#endregion


	//#region NAVIGATION


	//#endregion

}
