//#region IMPORT

import { ENUM_GENERAL_STATE } from "src/app/constants/enum.constant";
import { GeneralModel } from "./general.model";

//#endregion


//#region CLASS

export class BaseModel extends GeneralModel
{
	/*
		Attribute - START
		Description : Attribute for Base model, default class for model. It will record when it created and updated, also store the general state.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020.				Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						Updated by : Ibrahim Aziz.
		Version : 1.0:2.
	*/

	TrialCalculatePremium?: boolean;
	CreatedBy?: number;
	CreatedOn?: Date;
	UpdatedBy?: number;
	UpdatedOn?: Date;
	State?: ENUM_GENERAL_STATE;

	/* Attribute - END */


	//#region CONSTRUCTOR

	constructor()
	{
		super();
	}

	//#endregion


	//#region CLEAR

	clearFootprint(): void
	{
		this.CreatedBy = undefined;
		this.UpdatedBy = undefined;
	}

	clearAllFootprint(): void
	{
		this.State = undefined;
		this.CreatedBy = undefined;
		this.CreatedOn = undefined;
		this.UpdatedBy = undefined;
		this.UpdatedOn = undefined;
	}

	//#endregion
}

//#endregion