//#region IMPORT

import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BranchService } from "src/app/services/branch.service";
import { CustomerService } from "src/app/services/customer.service";
import { SessionService } from "src/app/services/session.service";
import { NumberConstant } from "src/app/constants/number.constant";
import { TableModel } from "src/app/models/bases/table.model";
import { ENUM_GENDER_TYPE, ENUM_DOCUMENT_TYPE, ENUM_MARITAL_STATUS, ENUM_RELIGION_TYPE, ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { UserModel } from "src/app/models/user.model";
import { ResponseModel } from "src/app/models/response.model";
// import { HandshakeModel } from "src/app/models/handshake.model";
import { UserSessionModel } from "src/app/models/usersession.model";
import { RuleConstant } from "src/app/constants/rule.constant";
import { AgencyDocumentModel } from "src/app/models/agencydocument.model";
import { SupportingDocumentModel } from "src/app/models/supportingdocument.model";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { StringConstant } from "src/app/constants/string.constant";
import { BaseAuthourizeDetailComponent } from "../bases/baseauthourizedetail.component";
import { BranchModel } from "./../../models/branch.model";
import { UserAgentModel } from "./../../models/useragent.model";

//#endregion



//#region COMPONENT

@Component
(
	{
		selector: "app-signup",
		templateUrl: "./signup.component.html",
		styleUrls: ["./signup.component.sass"]
	}
)

//#endregion



//#region CLASS

export class SignupComponent extends BaseAuthourizeDetailComponent implements OnInit
{
	//#region DECLARATION

	public _numberStepperActive: number;

	public _booleanShow: boolean;
	public _booleanRole: any;
	public _booleanGender: any;
	public _booleanCertificate: boolean;

	public _arrayStringStepper: Array<string>;

	public _modelUserPublic: UserModel;
	public _modelUserDocument: AgencyDocumentModel;
	public _modelUserSupportingDocument: SupportingDocumentModel;
	public _modelTable: TableModel;
	public _modelUndefined: undefined;

	public _stringFileTypeAllowed: string;

	public _enumMaritalType = ENUM_MARITAL_STATUS;
	public _enumReligion = ENUM_RELIGION_TYPE;
	public _enumGenderType = ENUM_GENDER_TYPE;

	public _arraySupportingDocument: any = [];
	public _arrayBranch: any;

	public _dataSharingConsent: boolean;
	public _promotionSharingConsent: boolean;

	public _stringConstant = StringConstant;

	searchSubject = new Subject<string>();


	//#endregion


	//#region CONSTRUCTOR

	constructor(private _serviceCustomer: CustomerService, routeActivated: ActivatedRoute, location: Location, serviceSession: SessionService, router: Router, private _serviceBranch: BranchService)
	{
		super(routeActivated, location, serviceSession, router);

		this._booleanShow = false;
		this._booleanCertificate = false;
		this._dataSharingConsent = false;
		this._promotionSharingConsent = false;
		this._arrayStringStepper = ["Data Pribadi", "Dokumen Pendukung"];

		this._numberStepperActive = NumberConstant.NUMBER_COMPARE_VALUE_ONE;

		this._modelUserPublic = new UserModel();
		this._modelUserDocument = new AgencyDocumentModel();
		this._modelUserSupportingDocument = new SupportingDocumentModel();
		this._modelTable = new TableModel();

		this._stringFileTypeAllowed = RuleConstant.STRING_FILE_TYPE_ALLOWED;
		this._arrayBranch = [];

		this.searchSubject.pipe(
			debounceTime(1500) // Wait for 500ms pause in typing
		  ).subscribe((stringEmail: string) =>
		  {
		 	this.callSelectAgentByEmail(this, stringEmail);
		  }
		);

	}

	//#endregion


	//#region INITIALIZE

	ngOnInit(): void
	{
		this.callSelectBranchForAgent(this);
		if((this._modelUserCheck.AgentToken !== null && this._modelUserCheck.AgentToken !== undefined) || (this._modelUserCheck.AgentType !== null && this._modelUserCheck.AgentType !== undefined))
		{
			if(this._modelUserCheck.RegisterStatus === 2)
			{
				this._numberStepperActive = 2;
				this._modelUserDocument.AgentToken = this._modelUserCheck.AgentToken;
				this._modelUserDocument.AgentType = this._modelUserCheck.AgentType;
				this._modelUserPublic.AgentType = this._modelUserCheck.AgentType;
				this._booleanShow = true;
				this._booleanRole = this._modelUserDocument.AgentType;
				if(this._booleanRole === 0)
				{
					this.setInitialListModelAgentPersonalSupportingDocument();
				}
				else if(this._booleanRole === 1)
				{
					this.setInitialListModelAgentCorporateSupportingDocument();
				}
			}
		}
	}

	//#endregion


	//#region WEB SERVICE

	private callSelectBranchForAgent(componentCurrent: SignupComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);

		const modelBranch: BranchModel = new BranchModel();
		modelBranch.ID = this._modelUserPublic.BranchID;

		componentCurrent._modelTable.Page = 1;
		componentCurrent._modelTable.RowPerPage = 20;

		this._serviceBranch.selectBranchForAgent
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						const arrayBranchResponse: BranchModel = new BranchModel();
						arrayBranchResponse.setModelFromString(modelResponse.Data);
						let modelBranchTemp: any = [];
						modelBranchTemp = JSON.parse(arrayBranchResponse.Result);
						const arrayBranchTemp: any = [];
						arrayBranchTemp.push(modelBranchTemp);
						componentCurrent._arrayBranch = arrayBranchTemp[0];
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectBranchForAgent(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelTable);
	}

	callInsertUserForAgent(componentCurrent: SignupComponent): void
	{
		this._modelUserPublic.PhoneNumber = this._modelUserPublic.PhoneNumber?.toString();
		this._modelUserPublic.BranchID = Number(this._modelUserPublic.BranchID);
		this._modelUserPublic.AgentType = this._booleanRole;
		this._modelUserPublic.Gender = this._booleanGender;
		this._modelUserPublic.HasCertificate = this._booleanCertificate;

		// if(this._booleanRole === 0)
		// {
		// 	delete this._modelUserPublic.CompanyName;
		// }
		componentCurrent._functionUserInterface.setLoadingProgress(1);
		let modelResponseValidation: ResponseModel = this._modelUserPublic.validateFormUser();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponseValidation = this._modelUserPublic.validateFormUser();
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			this._serviceCustomer.insertUserForAgent
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._functionUserInterface.updateLoadingProgress();

							const modelUserResponse: UserModel = new UserModel();
							modelUserResponse.setModelFromString(modelResponse.Data);

							componentCurrent._serviceSession.setModelUserSignIn(modelUserResponse);
							componentCurrent._booleanShow = true;
							if(componentCurrent._booleanRole === 0)
							{
								componentCurrent.setInitialListModelAgentPersonalSupportingDocument();
							}
							else if(componentCurrent._booleanRole === 1)
							{
								componentCurrent.setInitialListModelAgentCorporateSupportingDocument();
							}

							componentCurrent._numberStepperActive = 2;
							componentCurrent.setEventEmitterNumber(componentCurrent._numberStepperActive);
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });


							const modelUserSession = new UserSessionModel();
							modelUserSession.UserToken = modelUserResponse.Token;
							componentCurrent._modelUserDocument.AgentToken = modelUserResponse.Token;

							componentCurrent._serviceSession.setUserSession(modelUserSession);
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				}
			}, this._modelUserPublic);
		}
	}

	callInsertSupportingDocumentForAgent(componentCurrent: SignupComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent._modelUserDocument.AgentType = componentCurrent._modelUserPublic.AgentType;
		// const filteredModelUserDocument = new AgencyDocumentModel();
		// filteredModelUserDocument.AgentToken = componentCurrent._modelUserDocument.AgentToken;
		// filteredModelUserDocument.AgentType = componentCurrent._modelUserDocument.AgentType;
		const modelResponseRequest: ResponseModel = componentCurrent._modelUserDocument.validateDocument();
		if(modelResponseRequest.State === ENUM_RESPONSE_STATE.Success)
		{
			componentCurrent._modelUserDocument.listModelAgentSupportingDocument = this.removeEmptyObjects(componentCurrent._modelUserDocument.listModelAgentSupportingDocument);
			// componentCurrent._modelUserDocument = filteredModelUserDocument;
			componentCurrent._serviceCustomer.insertSupportingDocumentForAgent
			({
				success(modelResponse: ResponseModel): void
				{
					modelResponse.MessageTitle = "Supporting Document Submit";

					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						componentCurrent._functionUserInterface.updateLoadingProgress();
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToList(); });
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					modelResponse.MessageTitle = "Supporting Document Submit Failed";
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callInsertSupportingDocumentForAgent(componentCurrent); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					modelResponse.MessageTitle = "Supporting Document Submit Failed";

					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelUserDocument);
		}
		else
		{
			componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponseRequest, () => {});
		}

	}

	callSelectAgentByEmail(componentCurrent: SignupComponent, stringEmail: any): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);
		stringEmail = this._modelUserPublic.Email;

		const modelCheckEmail: UserAgentModel = new UserAgentModel();
		modelCheckEmail.Email = stringEmail;
		let modelResponseValidation: ResponseModel = modelCheckEmail.validateEmail();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			modelResponseValidation = modelCheckEmail.validateEmail();
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			this._serviceCustomer.selectAgentByEmail
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							componentCurrent._functionUserInterface.updateLoadingProgress();

							const modelCheckEmailResponse: UserAgentModel = new UserAgentModel();
							modelCheckEmailResponse.setModelFromString(modelResponse.Data);

							const modelUserSession = new UserSessionModel();
							modelUserSession.UserToken = modelCheckEmailResponse.Token;

							if(modelCheckEmailResponse.RegisterStatus === 1)
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.goToSignIn(); });
							}
							else if(modelCheckEmailResponse.RegisterStatus === 2)
							{
								componentCurrent._numberStepperActive = 2;
								componentCurrent._modelUserDocument.AgentToken = modelCheckEmailResponse.AgentToken;
								componentCurrent._modelUserDocument.AgentType = modelCheckEmailResponse.AgentType;
								componentCurrent._modelUserPublic.AgentType = modelCheckEmailResponse.AgentType;
								componentCurrent._booleanShow = true;
								componentCurrent._booleanRole = componentCurrent._modelUserDocument.AgentType;
								if(componentCurrent._booleanRole === 0)
								{
									componentCurrent.setInitialListModelAgentPersonalSupportingDocument();
								}
								else if(componentCurrent._booleanRole === 1)
								{
									componentCurrent.setInitialListModelAgentCorporateSupportingDocument();
								}
							}

							// componentCurrent._serviceSession.setUserSession(modelUserSession);
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.updateLoadingProgress();
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => {});
				}
			}, modelCheckEmail);
		}
	}

	//#endregion


	//#region GETTER


	//#endregion


	//#region LISTENER


	//#endregion

	//#region NAVIGATION

	goToOTPEmail(): void
	{
		this._booleanShow = false;
	}

	//#endregion


	//#region FUNCTION

	;

	onDateChange(event: any): void
	{
		const tempDate = event.value;
		// Setting time to noon to avoid time zone issues
		tempDate.setHours(12, 0, 0, 0);
		this._modelUserPublic.CertificateExpirationDate = tempDate;
	}

	changeDataSharingConsent(event): void
	{
		this._dataSharingConsent = event.target.checked;
	}

	changePromotionSharingConsent(event): void
	{
		this._promotionSharingConsent = event.target.checked;
	}

	removeEmptyObjects(arr: any): any
	{
		return arr.filter(obj => Object.keys(obj).length >= 1 && obj.Name?.length >0 );
	}

	setEventEmitterNumber(numberIndex: number): void
	{
		this._numberStepperActive = numberIndex;
	}

	onSearchChange(stringEmail: string): void
	{
		this.searchSubject.next(stringEmail);
	}



	listenFileUpload(event, numType: ENUM_DOCUMENT_TYPE, numberIndex: number): void
	{
		const arrayFileUpload: Array<File> = event.target.files;
		if(arrayFileUpload != null && arrayFileUpload !== undefined && arrayFileUpload.length > 0)
		{
			let modelSupportingDocument: SupportingDocumentModel;

			for (const fileUpload of arrayFileUpload)
			{
				fileUpload.convertToBase64(
					(stringBase64) =>
					{
						// this._modelUserDocument.listModelAgentSupportingDocument = [];

						modelSupportingDocument = new SupportingDocumentModel();
						modelSupportingDocument.Format = fileUpload.type;
						modelSupportingDocument.Size = fileUpload.size;
						modelSupportingDocument.Name = fileUpload.name;
						modelSupportingDocument.Data = stringBase64;
						modelSupportingDocument.Extension = fileUpload.name.getFileExtension();
						modelSupportingDocument.DocumentType = numType;

						const modelResponse: ResponseModel = modelSupportingDocument.validateDocument();

						if (modelResponse.State === ENUM_RESPONSE_STATE.Success)
						{
							if (this._modelUserDocument.listModelAgentSupportingDocument)
							{
								this._modelUserDocument.listModelAgentSupportingDocument[numberIndex] = modelSupportingDocument;
							}
						}
						else
						{
							this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					},
					() =>
					{

					}
				);
			}
		}
	}

	public goToList()
	{
		this._router.navigate(["home"]);
	}

	public goToSignIn()
	{
		this._router.navigate(["signin"]);
	}

	//#endregion


	//#region SETTER

	private setInitialListModelAgentPersonalSupportingDocument(): void
	{
		this._modelUserDocument.listModelAgentSupportingDocument?.push(new SupportingDocumentModel());
		this._modelUserDocument.listModelAgentSupportingDocument?.push(new SupportingDocumentModel());
		this._modelUserDocument.listModelAgentSupportingDocument?.push(new SupportingDocumentModel());
		this._modelUserDocument.listModelAgentSupportingDocument?.push(new SupportingDocumentModel());
	}

	private setInitialListModelAgentCorporateSupportingDocument(): void
	{
		this._modelUserDocument.listModelAgentSupportingDocument?.push(new SupportingDocumentModel());
		this._modelUserDocument.listModelAgentSupportingDocument?.push(new SupportingDocumentModel());
		this._modelUserDocument.listModelAgentSupportingDocument?.push(new SupportingDocumentModel());
		this._modelUserDocument.listModelAgentSupportingDocument?.push(new SupportingDocumentModel());
		this._modelUserDocument.listModelAgentSupportingDocument?.push(new SupportingDocumentModel());
		this._modelUserDocument.listModelAgentSupportingDocument?.push(new SupportingDocumentModel());
		this._modelUserDocument.listModelAgentSupportingDocument?.push(new SupportingDocumentModel());
		this._modelUserDocument.listModelAgentSupportingDocument?.push(new SupportingDocumentModel());
		this._modelUserDocument.listModelAgentSupportingDocument?.push(new SupportingDocumentModel());
	}
}

//#endregion