import { Component } from "@angular/core";

@Component({
	selector: "app-premiumcalculator",
	templateUrl: "./premiumcalculator.component.html",
	styleUrls: ["./premiumcalculator.component.sass"]
})
export class PremiumCalculatorComponent
{

}
