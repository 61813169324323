<div id="divSignInContainer">
	<section class="SectionFormSignInContainer" *ngIf="_booleanShow === false">
		<ng-container>
			<header class="HeaderSignIn">
				<img src="../../../assets/icons/sakina/icon_bri_insurance.svg" alt="BRI Insurance - Logo BRI Insurance">
			</header>
			<header class="HeaderSignInResponsive">
				<img src="../../../assets/icons/sakina/icon_bri_insurance.svg" alt="BRI Insurance - Logo BRI Insurance">
				<img src="../../assets/logos/sakina/logo_ams.svg" alt="BRI Insurance - Logo AMS">
			</header>
		</ng-container>
		<form>
			<div class="DivFormHeader" style="text-align: center;">
				<h1>Sign Up</h1>
				<p>Buat akun Anda untuk mengakses website Agent management System dari BRI Insurance</p>
			</div>

			<div class="DivForm" style="padding: 5vw;">
				<app-horizontalstepperinclude [_arrayContent]="this._arrayStringStepper" [_numberToggle]="_numberStepperActive"
					(_eventEmitterNumber)="setEventEmitterNumber($event);"></app-horizontalstepperinclude>
				<h3>Akun</h3>
				<p for="radioButtonLicensePlateCalculate" class="SemiBold">Anda Sebagai?</p>
				<div id="valueContainer" class="DivRadioButtonContainer">
					<label class="LabelRadioButtonContainer" for="radioButtonForPersonal"
						[ngClass]="{ 'LabelRadioButtonActive' : _booleanRole}">
						<input type="radio" name="radioButtonForPersonal" [(ngModel)]="_booleanRole"
							[ngModelOptions]="{standalone: true}" id="radioButtonForPersonal" [value]=0>Individu
					</label>

					<label class="LabelRadioButtonContainer" for="radioButtonForBusiness"
						[ngClass]="{ 'LabelRadioButtonActive' : _booleanRole}">
						<input type="radio" name="radioButtonForBusiness" [(ngModel)]="_booleanRole"
							[ngModelOptions]="{standalone: true}" id="radioButtonForBusiness" [value]=1>Badan Usaha
					</label>
				</div>
				<h3>Data</h3>
				<div class="DivFormHorizontalContainer">
					<div class="DivForm2Column" style="margin-top: 0px;">
						<fieldset>
							<section class="SectionFormInput">
								<div class="DivFormInput">
									<p for="textFullName" class="LabelPlaceholderGeneral">Nama Lengkap (Sesuai KTP)</p>
									<input type="text" id="textFirstName" name="modelUserFullName"
										[ngModelOptions]="{standalone: true}" placeholder="Masukkan Nama Lengkap"
										autocomplete="off" [(ngModel)]="_modelUserPublic.FullName">
								</div>
							</section>

							<p style="margin:0" for="radioButtonLicensePlateCalculate" class="SemiBold">Jenis Kelamin
							</p>
							<div style="margin:0;height: 50px;" id="valueContainer" class="DivRadioButtonContainer">
								<label class="LabelRadioButtonContainer" for="radioButtonForMale"
									[ngClass]="{ 'LabelRadioButtonActive' : _booleanGender}">
									<input type="radio" name="radioButtonForMale" [(ngModel)]="_booleanGender"
										[ngModelOptions]="{standalone: true}" id="radioButtonForMale"
										[value]=1>Laki-Laki
								</label>

								<label class="LabelRadioButtonContainer" for="radioButtonForFemale"
									[ngClass]="{ 'LabelRadioButtonActive' : _booleanGender}">
									<input type="radio" name="radioButtonForFemale" [(ngModel)]="_booleanGender"
										[ngModelOptions]="{standalone: true}" id="radioButtonForFemale"
										[value]=0>Perempuan
								</label>
							</div>

							<section class="SectionFormInput">
								<div class="DivFormInput">
									<p for="textBirthPlace" class="LabelPlaceholderGeneral">Tempat Lahir</p>
									<input type="text" id="textBirthPlace" name="modelUserBirthPlace"
										[ngModelOptions]="{standalone: true}" placeholder="Masukkan Tempat Lahir"
										autocomplete="off" [(ngModel)]="_modelUserPublic.BirthPlace">
								</div>
							</section>


							<section class="SectionFormInput">
								<div class="DivFormInput">
									<p for="dateModelBirthDate" class="LabelPlaceholderGeneral">Tanggal Lahir*</p>
									<div class="DivDateTimePicker">
										<mat-form-field class="matFormFieldPolicyholderExpiredDrivingLicense"
											appearance="fill">
											<input id="dateModelProductGeneralPolicyholderExpiredDrivingLicense"
												[(ngModel)]="_modelUserPublic.BirthDate"
												[ngModelOptions]="{standalone: true}" matInput
												[matDatepicker]="dpBornDate" placeholder="Pilih Tanggal Lahir">
											<mat-datepicker-toggle matSuffix [for]="dpBornDate"></mat-datepicker-toggle>
											<mat-datepicker #dpBornDate startView="month" panelClass="month-picker">
											</mat-datepicker>
										</mat-form-field>
									</div>
								</div>
							</section>

							<section class="SectionFormInput">
								<div class="DivFormInput">
									<p for="textEmail" class="LabelPlaceholderGeneral">Email</p>
									<input type="text" id="textEmail" name="modelUserEmail" placeholder="Masukkan Email"
										(ngModelChange)="onSearchChange($event)" [ngModelOptions]="{standalone: true}"
										autocomplete="off" [(ngModel)]="_modelUserPublic.Email">
								</div>
							</section>

							<div class="DivFormMobilePhoneContainer">
								<section class="SectionFormInput">
									<div class="DivFormInput">
										<p for="textCountryCode" class="LabelPlaceholderGeneral">Kode</p>
										<input type="text" id="textCountryCode" name="modelUserCountryCodel"
											placeholder="+62" autocomplete="off" disabled>
									</div>
								</section>
								<section class="SectionFormInput">
									<div class="DivFormInput">
										<p for="textPhoneNumber" class="LabelPlaceholderGeneral">Nomor Ponsel</p>
										<input type="text" id="textPhoneNumber" name="modelUserPhoneNumber"
											[ngModelOptions]="{standalone: true}" placeholder="Masukkan Nomor Ponsel"
											autocomplete="off" [(ngModel)]="_modelUserPublic.PhoneNumber">
									</div>
								</section>
							</div>

							<section class="SectionFormInput">
								<div class="DivFormInput">
									<p for="textID" class="LabelPlaceholderGeneral">KTP</p>
									<input type="text" id="textID" name="modelUserID"
										[ngModelOptions]="{standalone: true}" placeholder="Masukkan Nomor KTP"
										autocomplete="off" [(ngModel)]="_modelUserPublic.IdentificationNumber">
								</div>
							</section>

							<section class="SectionFormInput">
								<div class="DivFormInput">
									<p for="textID" class="LabelPlaceholderGeneral">NPWP</p>
									<input type="text" id="textNPWP" name="modelUserNPWP" placeholder="Masukkan NPWP"
										[ngModelOptions]="{standalone: true}" autocomplete="off"
										[(ngModel)]="_modelUserPublic.TaxNumber">
								</div>
							</section>
						</fieldset>
					</div>

					<div class="DivForm2Column">
						<fieldset>
							<section class="SectionFormInput">
								<div class="DivFormInput">
									<p for="selectMaritalStatus" class="LabelPlaceholderGeneral">Status Perkawinan</p>
									<select [(ngModel)]="_modelUserPublic.MaritalStatus" name="selectMaritalStatus">
										<option [ngValue]="_modelUndefined">Pilih Status Perkawinan</option>
										<option [ngValue]="_enumMaritalType.Single">Lajang</option>
										<option [ngValue]="_enumMaritalType.Married">Menikah</option>
										<option [ngValue]="_enumMaritalType.Divorce">Cerai Hidup</option>
										<option [ngValue]="_enumMaritalType.Widow">Cerai Mati</option>
									</select>
								</div>
							</section>

							<section class="SectionFormInput">
								<div class="DivFormInput">
									<p for="selectReligion" class="LabelPlaceholderGeneral">Agama</p>
									<select [(ngModel)]="_modelUserPublic.ReligionType" name="selectReligion">
										<option [ngValue]="_modelUndefined">Pilih Agama</option>
										<option [ngValue]="_enumReligion.Islam">Islam</option>
										<option [ngValue]="_enumReligion.Christianity">Kristen</option>
										<option [ngValue]="_enumReligion.Catholicism">Katolik</option>
										<option [ngValue]="_enumReligion.Buddhism">Buddha</option>
										<option [ngValue]="_enumReligion.Hinduism">Hindu</option>
										<option [ngValue]="_enumReligion.Confucianism">Konghucu</option>
									</select>
								</div>
							</section>

							<section class="SectionFormInputTextArea">
								<div class="DivFormInputTextarea">
									<p>Alamat</p>
									<textarea name="textAddress" placeholder="Masukkan Detail Alamat"
										[(ngModel)]="_modelUserPublic.Address"></textarea>
								</div>
							</section>

							<section class="SectionFormInput">
								<div class="DivFormInput">
									<p for="textAccountNumber" class="LabelPlaceholderGeneral">Nomor Rekening BRI</p>
									<input type="text" id="textAccountNumber" name="modelUserAccountNumber"
										[ngModelOptions]="{standalone: true}" placeholder="Masukkan Nomor Rekening BRI"
										autocomplete="off" [(ngModel)]="_modelUserPublic.BRIAccountNumber">
								</div>
							</section>

							<section class="SectionFormInput">
								<div class="DivFormInput">
									<p for="selectBranch" class="LabelPlaceholderGeneral">Cabang</p>
									<select [(ngModel)]="_modelUserPublic.BranchID" name="selectBranch">
										<option [ngValue]="_modelUndefined">Pilih Cabang</option>
										<option *ngFor="let modelBranch of _arrayBranch" [value]="modelBranch.ID">
											{{ modelBranch.Name }}
										</option>
									</select>
								</div>
							</section>

							<section class="SectionFormInput">
								<div class="DivFormInput">
									<p for="textMothersMaiden" class="LabelPlaceholderGeneral">Nama Ibu Kandung</p>
									<input type="text" id="textMothersMaiden" name="modelUserMothersMaiden"
										[ngModelOptions]="{standalone: true}" placeholder="Masukkan Nama Ibu Kandung"
										autocomplete="off" [(ngModel)]="_modelUserPublic.MothersMaidenName">
								</div>
							</section>

							<section *ngIf="_booleanRole === 1" class="SectionFormInput">
								<div class="DivFormInput">
									<p for="textID" class="LabelPlaceholderGeneral">Nama Perusahaan</p>
									<input type="text" id="textCompanyName" name="modelUserCompanyName"
										placeholder="Masukkan Nama Perusahaan" [ngModelOptions]="{standalone: true}"
										autocomplete="off" [(ngModel)]="_modelUserPublic.CompanyName">
								</div>
							</section>
						</fieldset>
					</div>
				</div>

				<h3>Sertifikat</h3>
				<p for="radioButtonLicensePlateCalculate" class="SemiBold">Apakah Anda mempunyai sertifikat?</p>
				<div class="DivFormHorizontalContainer">
					<div class="DivForm2Column">
						<div class="DivRadioButtonContainer">
							<label class="LabelRadioButtonContainer" for="radioButtonLicensePlateForCalculateTrue"
								[ngClass]="{ 'LabelRadioButtonActive' : _booleanCertificate}">
								<input type="radio" name="radioButtonLicensePlateForCalculateTrue"
									[(ngModel)]="_booleanCertificate" id="radioButtonLicensePlateForCalculateTrue"
									[value]=true>Ya
							</label>

							<label class="LabelRadioButtonContainer" for="radioButtonLicensePlateForCalculateFalse"
								[ngClass]="{ 'LabelRadioButtonActive' : _booleanCertificate}">
								<input type="radio" name="radioButtonLicensePlateForCalculateFalse"
									[(ngModel)]="_booleanCertificate" id="radioButtonLicensePlateForCalculateFalse"
									[value]=false>Tidak
							</label>
						</div>
					</div>

					<div class="DivForm2Column">
						<fieldset>
							<section *ngIf="_booleanCertificate" class="SectionFormInput">
								<div class="DivFormInput">
									<p for="dateModelPeriodDate" class="LabelPlaceholderGeneral">Tanggal Kadaluarsa
										Sertifikat*</p>
									<div class="DivDateTimePicker">
										<mat-form-field class="matFormFieldPolicyholderExpiredDrivingLicense"
											appearance="fill">
											<input id="dateModelPeriodDate" [ngModelOptions]="{standalone: true}" (dateChange)="onDateChange($event)"
												matInput [matDatepicker]="dpPeriodDate" placeholder="Pilih Tanggal"
												[(ngModel)]="_modelUserPublic.CertificateExpirationDate">
											<mat-datepicker-toggle matSuffix
												[for]="dpPeriodDate"></mat-datepicker-toggle>
											<mat-datepicker #dpPeriodDate startView="month" panelClass="month-picker">
											</mat-datepicker>
										</mat-form-field>
									</div>
								</div>
							</section>
						</fieldset>
					</div>
				</div>
			</div>

			<div class="DivButtonContainerSmallCenter">
				<input type="button" value="LANJUTKAN" (click)="callInsertUserForAgent(this)">
			</div>
		</form>

		<div class="DivAlignCenterContainer">
			<p (click)="goToSignIn()">Sudah memilki akun? <a><b>Masuk Disini</b></a></p>
		</div>
	</section>

	<section class="SectionFormSignInContainer" *ngIf="_booleanShow === true">
		<ng-container>
			<header class="HeaderSignIn">
				<img src="../../../assets/icons/sakina/icon_bri_insurance.svg" alt="BRI Insurance - Logo BRI Insurance">
			</header>
			<header class="HeaderSignInResponsive">
				<img src="../../../assets/icons/sakina/icon_bri_insurance.svg" alt="BRI Insurance - Logo BRI Insurance">
				<img src="../../assets/logos/sakina/logo_ams.svg" alt="BRI Insurance - Logo AMS">
			</header>
		</ng-container>
		<form>
			<div class="DivFormHeader" style="text-align: center;">
				<h1>Sign Up</h1>
				<p>Buat akun Anda untuk mengakses website Agent management System dari BRI Insurance</p>
			</div>

			<div class="DivForm" style="padding: 5vw">
				<app-horizontalstepperinclude [_arrayContent]="this._arrayStringStepper" [_numberToggle]="_numberStepperActive"
					(_eventEmitterNumber)="setEventEmitterNumber($event);"></app-horizontalstepperinclude>

				<h3>Dokumen Pendukung</h3>
				<div class="DivFormHorizontalContainer">
					<div class="DivForm2Column" style="margin-top: 0px;">
						<fieldset>
							<section class="SectionFormInput">
								<div class="DivFormInput"
									[ngClass]="{'DivFormInputWithAttachment': _modelUserDocument.listModelAgentSupportingDocument?.at(0)?.Name}">
									<figure *ngIf="_modelUserDocument.listModelAgentSupportingDocument?.at(0)?.Name"
										class="FigureInputAttachment">
										<img src="{{ _modelUserDocument.listModelAgentSupportingDocument?.at(0)?.Extension | convertExtensionToIcon }}"
											alt="{{ _modelUserDocument.listModelAgentSupportingDocument?.at(0)?.Name }}">
									</figure>
									<p for="fileKTPDocs" class="LabelPlaceholderSignIn">Identification Number (KTP)</p>
									<label class="LabelInputFile" for="fileKTPDocs">
										<input type="file" id="fileKTPDocs" name="modelUserFileKTP"
											(change)="listenFileUpload($event, 0, 0)" placeholder="Masukkan KTP"
											autocomplete="off">
										<span>{{ _modelUserDocument.listModelAgentSupportingDocument?.at(0)?.Name ??
											"Unggah KTP dengan maksimal ukuran 20 MB" }}</span>
									</label>
								</div>
							</section>

							<section class="SectionFormInput">
								<div class="DivFormInput"
									[ngClass]="{'DivFormInputWithAttachment': _modelUserDocument.listModelAgentSupportingDocument?.at(1)?.Name}">
									<figure *ngIf="_modelUserDocument.listModelAgentSupportingDocument?.at(1)?.Name"
										class="FigureInputAttachment">
										<img src="{{ _modelUserDocument.listModelAgentSupportingDocument?.at(1)?.Extension | convertExtensionToIcon }}"
											alt="{{ _modelUserDocument.listModelAgentSupportingDocument?.at(1)?.Name }}">
									</figure>
									<p for="fileNPWPDocs" class="LabelPlaceholderSignIn">NPWP</p>
									<label class="LabelInputFile" for="fileNPWPDocs">
										<input type="file" id="fileNPWPDocs" name="modelUserFileNPWP"
											(change)="listenFileUpload($event, 1, 1)" placeholder="Masukkan NPWP"
											autocomplete="off">
										<span>{{ _modelUserDocument.listModelAgentSupportingDocument?.at(1)?.Name ??
											"Unggah NPWP dengan maksimal ukuran 20 MB" }}</span>
									</label>
								</div>
							</section>

							<section *ngIf="_booleanRole === 1" class="SectionFormInput">
								<div class="DivFormInput"
									[ngClass]="{'DivFormInputWithAttachment': _modelUserDocument.listModelAgentSupportingDocument?.at(2)?.Name}">
									<figure *ngIf="_modelUserDocument.listModelAgentSupportingDocument?.at(2)?.Name"
										class="FigureInputAttachment">
										<img src="{{ _modelUserDocument.listModelAgentSupportingDocument?.at(2)?.Extension | convertExtensionToIcon }}"
											alt="{{ _modelUserDocument.listModelAgentSupportingDocument?.at(2)?.Name }}">
									</figure>
									<p for="fileNPWPCorporateDocs" class="LabelPlaceholderSignIn">NPWP Perwakilan Badan
										Usaha</p>
									<label class="LabelInputFile" for="fileNPWPCorporateDocs">
										<input type="file" id="fileNPWPCorporateDocs" name="modelUserFileNPWPCorporate"
											(change)="listenFileUpload($event, 2, 2)"
											placeholder="Masukkan NPWP Perwakilan Badan Usaha" autocomplete="off">
										<span>{{ _modelUserDocument.listModelAgentSupportingDocument?.at(2)?.Name ??
											"Unggah NPWP dengan maksimal ukuran 20 MB" }}</span>
									</label>
								</div>
							</section>

							<section *ngIf="_booleanRole === 1" class="SectionFormInput">
								<div class="DivFormInput"
									[ngClass]="{'DivFormInputWithAttachment': _modelUserDocument.listModelAgentSupportingDocument?.at(5)?.Name}">
									<figure *ngIf="_modelUserDocument.listModelAgentSupportingDocument?.at(5)?.Name"
										class="FigureInputAttachment">
										<img src="{{ _modelUserDocument.listModelAgentSupportingDocument?.at(5)?.Extension | convertExtensionToIcon }}"
											alt="{{ _modelUserDocument.listModelAgentSupportingDocument?.at(5)?.Name }}">
									</figure>
									<p for="fileNotarialDeedDocs" class="LabelPlaceholderSignIn">Akta Notaris</p>
									<label class="LabelInputFile" for="fileNotarialDeedDocs">
										<input type="file" id="fileNotarialDeedDocs" name="modelUserFileNotarialDeed"
											(change)="listenFileUpload($event, 5, 5)"
											placeholder="Masukkan Akta Notaris" autocomplete="off">
										<span>{{ _modelUserDocument.listModelAgentSupportingDocument?.at(5)?.Name ??
											"Unggah Akta Notaris dengan maksimal ukuran 20 MB" }}</span>
									</label>
								</div>
							</section>

							<section *ngIf="_booleanRole === 1 && _booleanCertificate" class="SectionFormInput">
								<div class="DivFormInput"
									[ngClass]="{'DivFormInputWithAttachment': _modelUserDocument.listModelAgentSupportingDocument?.at(4)?.Name}">
									<figure *ngIf="_modelUserDocument.listModelAgentSupportingDocument?.at(4)?.Name"
										class="FigureInputAttachment">
										<img src="{{ _modelUserDocument.listModelAgentSupportingDocument?.at(4)?.Extension | convertExtensionToIcon }}"
											alt="{{ _modelUserDocument.listModelAgentSupportingDocument?.at(4)?.Name }}">
									</figure>
									<p for="fileCertificateDocs" class="LabelPlaceholderSignIn">Sertifikat<span
											class="SpanPlaceholderGeneral">(Opsional)</span></p>
									<label class="LabelInputFile" for="fileCertificateDocs">
										<input type="file" id="fileCertificateDocs" name="modelUserFileCertificate"
											(change)="listenFileUpload($event, 4, 4)" placeholder="Masukkan Certificate"
											autocomplete="off">
										<span>{{ _modelUserDocument.listModelAgentSupportingDocument?.at(4)?.Name ??
											"Unggah Sertifikat dengan maksimal ukuran 20 MB" }}</span>
									</label>
								</div>
							</section>
						</fieldset>
					</div>

					<div class="DivForm2Column" style="margin-top: 0px;">
						<fieldset>
							<section *ngIf="_booleanRole === 0" class="SectionFormInput">
								<div class="DivFormInput"
									[ngClass]="{'DivFormInputWithAttachment': _modelUserDocument.listModelAgentSupportingDocument?.at(2)?.Name}">
									<figure *ngIf="_modelUserDocument.listModelAgentSupportingDocument?.at(2)?.Name"
										class="FigureInputAttachment">
										<img src="{{ _modelUserDocument.listModelAgentSupportingDocument?.at(2)?.Extension | convertExtensionToIcon }}"
											alt="{{ _modelUserDocument.listModelAgentSupportingDocument?.at(2)?.Name }}">
									</figure>
									<p for="fileDebitBookDocs" class="LabelPlaceholderSignIn">Buku Tabungan BRI an.
										Perwakilan Badan Usaha</p>
									<label class="LabelInputFile" for="fileDebitBookDocs">
										<input type="file" id="fileDebitBookDocs" name="modelUserFileDebitBook"
											(change)="listenFileUpload($event, 3, 2)"
											placeholder="Masukkan Nomor Rekening BRI" autocomplete="off">
										<span>{{ _modelUserDocument.listModelAgentSupportingDocument?.at(2)?.Name ??
											"Unggah Buku Tabungan BRI dengan maksimal ukuran 20 MB" }}</span>
									</label>
								</div>
							</section>

							<section *ngIf="_booleanRole === 1" class="SectionFormInput">
								<div class="DivFormInput"
									[ngClass]="{'DivFormInputWithAttachment': _modelUserDocument.listModelAgentSupportingDocument?.at(3)?.Name}">
									<figure *ngIf="_modelUserDocument.listModelAgentSupportingDocument?.at(3)?.Name"
										class="FigureInputAttachment">
										<img src="{{ _modelUserDocument.listModelAgentSupportingDocument?.at(3)?.Extension | convertExtensionToIcon }}"
											alt="{{ _modelUserDocument.listModelAgentSupportingDocument?.at(3)?.Name }}">
									</figure>
									<p for="fileDebitBookDocs" class="LabelPlaceholderSignIn">Buku Tabungan BRI an.
										Perwakilan Badan Usaha</p>
									<label class="LabelInputFile" for="fileDebitBookDocs">
										<input type="file" id="fileDebitBookDocs" name="modelUserFileDebitBook"
											(change)="listenFileUpload($event, 3, 3)"
											placeholder="Masukkan Nomor Rekening BRI" autocomplete="off">
										<span>{{ _modelUserDocument.listModelAgentSupportingDocument?.at(3)?.Name ??
											"Unggah Buku Tabungan BRI dengan maksimal ukuran 20 MB" }}</span>
									</label>
								</div>
							</section>

							<section *ngIf="_booleanRole === 0 && _booleanCertificate" class="SectionFormInput">
								<div class="DivFormInput"
									[ngClass]="{'DivFormInputWithAttachment': _modelUserDocument.listModelAgentSupportingDocument?.at(3)?.Name}">
									<figure *ngIf="_modelUserDocument.listModelAgentSupportingDocument?.at(3)?.Name"
										class="FigureInputAttachment">
										<img src="{{ _modelUserDocument.listModelAgentSupportingDocument?.at(3)?.Extension | convertExtensionToIcon }}"
											alt="{{ _modelUserDocument.listModelAgentSupportingDocument?.at(3)?.Name }}">
									</figure>
									<p for="fileCertificateDocs" class="LabelPlaceholderSignIn">Sertifikat*<span
											class="SpanPlaceholderGeneral">(Opsional)</span></p>
									<label class="LabelInputFile" for="fileCertificateDocs">
										<input type="file" id="fileCertificateDocs" name="modelUserFileCertificate"
											(change)="listenFileUpload($event, 4, 3)" placeholder="Masukkan Certificate"
											autocomplete="off">
										<span>{{ _modelUserDocument.listModelAgentSupportingDocument?.at(3)?.Name ??
											"Unggah Sertifikat dengan maksimal ukuran 20 MB" }}</span>
									</label>
								</div>
							</section>

							<section *ngIf="_booleanRole === 1" class="SectionFormInput">
								<div class="DivFormInput"
									[ngClass]="{'DivFormInputWithAttachment': _modelUserDocument.listModelAgentSupportingDocument?.at(6)?.Name}">
									<figure *ngIf="_modelUserDocument.listModelAgentSupportingDocument?.at(6)?.Name"
										class="FigureInputAttachment">
										<img src="{{ _modelUserDocument.listModelAgentSupportingDocument?.at(6)?.Extension | convertExtensionToIcon }}"
											alt="{{ _modelUserDocument.listModelAgentSupportingDocument?.at(6)?.Name }}">
									</figure>
									<p for="fileDomicileDocs" class="LabelPlaceholderSignIn">Surat Domisili<span
											class="SpanPlaceholderGeneral">(Opsional)</span></p>
									<label class="LabelInputFile" for="fileDomicileDocs">
										<input type="file" id="fileDomicileDocs" name="modelUserFileDomicileDocs"
											(change)="listenFileUpload($event, 6, 6)"
											placeholder="Masukkan Surat Domisili" autocomplete="off">
										<span>{{ _modelUserDocument.listModelAgentSupportingDocument?.at(6)?.Name ??
											"Unggah Surat Domisili dengan maksimal ukuran 20 MB" }}</span>
									</label>
								</div>
							</section>

							<section *ngIf="_booleanRole === 1" class="SectionFormInput">
								<div class="DivFormInput"
									[ngClass]="{'DivFormInputWithAttachment': _modelUserDocument.listModelAgentSupportingDocument?.at(7)?.Name}">
									<figure *ngIf="_modelUserDocument.listModelAgentSupportingDocument?.at(7)?.Name"
										class="FigureInputAttachment">
										<img src="{{ _modelUserDocument.listModelAgentSupportingDocument?.at(7)?.Extension | convertExtensionToIcon }}"
											alt="{{ _modelUserDocument.listModelAgentSupportingDocument?.at(7)?.Name }}">
									</figure>
									<p for="fileSIUPDocs" class="LabelPlaceholderSignIn">SIUP<span
											class="SpanPlaceholderGeneral">(Opsional)</span></p>
									<label class="LabelInputFile" for="fileSIUPDocs">
										<input type="file" id="fileSIUPDocs" name="modelUserFileSIUPDocs"
											(change)="listenFileUpload($event, 7, 7)" placeholder="Masukkan SIUP"
											autocomplete="off">
										<span>{{ _modelUserDocument.listModelAgentSupportingDocument?.at(7)?.Name ??
											"Unggah SIUP dengan maksimal ukuran 20 MB" }}</span>
									</label>
								</div>
							</section>

							<section *ngIf="_booleanRole === 1" class="SectionFormInput">
								<div class="DivFormInput"
									[ngClass]="{'DivFormInputWithAttachment': _modelUserDocument.listModelAgentSupportingDocument?.at(8)?.Name}">
									<figure *ngIf="_modelUserDocument.listModelAgentSupportingDocument?.at(8)?.Name"
										class="FigureInputAttachment">
										<img src="{{ _modelUserDocument.listModelAgentSupportingDocument?.at(8)?.Extension | convertExtensionToIcon }}"
											alt="{{ _modelUserDocument.listModelAgentSupportingDocument?.at(8)?.Name }}">
									</figure>
									<p for="fileNIBDocs" class="LabelPlaceholderSignIn">NIB</p>
									<label class="LabelInputFile" for="fileNIBDocs">
										<input type="file" id="fileNIBDocs" name="modelUserFileNIB"
											(change)="listenFileUpload($event, 8, 8)" placeholder="Masukkan NIB"
											autocomplete="off">
										<span>{{ _modelUserDocument.listModelAgentSupportingDocument?.at(8)?.Name ??
											"Unggah NIB dengan maksimal ukuran 20 MB" }}</span>
									</label>
								</div>
							</section>
						</fieldset>
					</div>
				</div>

				<div style="margin:15px" class="DivForm Margin15">
					<div class="DivFormBubble">
						<label style="font-family:Poppins-Bold">BRI Asuransi Indonesia kepada calon Agen BRINS, saya sebagai pendaftar menyatakan mengerti dan menyetujui, bahwa :</label>
						<div><br></div>
						<div class="DivCheckBoxContainer MarginBottom15">
							<label class="LabelCheckboxContainer" for="checkboxApproveDataSharing">
								<input type="checkbox" name="checkboxApproveDataSharing" [(ngModel)]="_dataSharingConsent" (change)="changeDataSharingConsent($event)"
									id="checkboxApproveDataSharing">
								{{_stringConstant.STRING_APPROVAL_USE_CUSTOMER_DATA_FIRST}}
							</label>
						</div>
					</div>

					<div id="divPDPContainer">
						<section class="SectionBubbleCard">
							<div class="DivImageBubleCard">
								<img src="../../../assets/icons/sakina/icon_note_pdp.svg"
									alt="BRI Insurance - Icon Rounded">
							</div>
							<div class="DivContentBubleCard">
								<label style="font-family:Poppins-Bold; font-size: 20px;">Catatan</label>
								<p>{{_stringConstant.STRING_APPROVAL_USE_CUSTOMER_DATA_NOTE}}</p>
							</div>
						</section>
					</div>

				</div>
			</div>


			<div class="DivButtonContainerSmallCenter" *ngIf="_dataSharingConsent">
				<input type="button" value="DAFTAR" (click)="callInsertSupportingDocumentForAgent(this)">
				<p (click)="goToSignIn()">Sudah memilki akun? <a><b>Masuk Disini</b></a></p>
			</div>
		</form>
	</section>
</div>