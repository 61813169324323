<div class="DivBodyContainer" (scroll)="scrolled($event)">
    <div id="divBodyHomeMenu">
		<nav id="navigationMainHome" [ngClass]="_booleanMenuMaximize ? 'NavHeaderMaximize' : 'NavHeaderMinimize' " (click)="!_booleanMenuMaximize && minimizeMenu(true)">
			<img id="imageLogoHeader" (click)="goToHome()" src="../../assets/logos/sakina/logo_brins_primary.svg" alt="BRI Insurance - Logo BRI Insurance">
			<ng-container *ngIf="_booleanMenuMaximize">
				<ul *ngIf="_booleanMenuMaximize">
					<li [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Home) }" (click)="goToHome()">
						<label>Halaman Utama</label>
					</li>
					<li [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.PremiumCalculator) }" (click)="goToPremiumCalculator();">
						<label>Kalkulator Premi</label>
					</li>
				</ul>

				<div class="DivNavigationRight">
					<li class="ListNavigationMenu">
						<a class="ANavigationMenu"><img class="ImageIconMenu" src="../../assets/icons/sakina/icon_profile.svg"></a>
						<div class="DivNavigationDetail">
							<ul>
								<ng-container *ngIf="_modelUserSignIn.FirstName !== null && _modelUserSignIn.FirstName !== undefined && _modelUserSignIn.FirstName !== ''">
									<li>
										<a class="ANavigationMenuList" (click)="goToProfile()">Profil</a>
									</li>
									<li>
										<a class="ANavigationMenuList" (click)="callServiceSignOut()">Signout</a>
									</li>
								</ng-container>

								<li *ngIf="_modelUserSignIn.FirstName === null || _modelUserSignIn.FirstName === undefined || _modelUserSignIn.FirstName === ''">
									<a class="ANavigationMenuList" (click)="goToSignIn()">Masuk</a>
								</li>
							</ul>
						</div>
					</li>
					<li class="ListNavigationMenu" *ngIf="_modelUserSignIn.FirstName !== null && _modelUserSignIn.FirstName !== undefined && _modelUserSignIn.FirstName !== ''">
						<a class="ANavigationMenu" *ngIf="_booleanNotification === true"><img class="ImageIconMenu" src="../../assets/icons/sakina/icon_notification.svg" (click)="goToNotification();"></a>
						<a class="ANavigationMenu" *ngIf="_booleanNotification === false"><img class="ImageIconMenu" src="../../assets/icons/sakina/icon_notification_black.svg" (click)="goToNotification();"></a>
					</li>
					<img id="imageLogoDigital" (click)="goToHome()" src="../../assets/logos/sakina/logo_ams.svg" alt="BRI Insurance - Logo Header AMS">
					<!-- <img (click)="minimizeMenu(false)" class="ImageIconMenu" src="../../assets/icons/sakina/icon_close.svg"> -->
				</div>
			</ng-container>

			<ng-container *ngIf="!_booleanMenuMaximize">
				<div class="DivNavigationRight">
					<ul>
						<li>
							<img id="imageIconHamburger" (click)="minimizeMenu(true)" class="ImageIconMenu" src="../../assets/icons/sakina/icon_hamburger_menu.svg">
						</li>
					</ul>
				</div>
			</ng-container>
		</nav>
		
		<nav id="navigationAside" class="NavMenuOverlay" [ngClass]="_booleanMenuBarMinimize ? 'NavMenuMinimizeOverlay' : 'NavMenuMinimizeNoOverlay'" >

			<div class="DivMenuHeader">
				<img id="imageLogoDigital" (click)="goToHome()" src="../../assets/logos/sakina/logo_ams.svg" alt="BRI Insurance - Logo Header Digital">
				<img (click)="minimizeMenu(false)" class="ImageIconMenu" src="../../assets/icons/sakina/icon_close.svg">
			</div>

			<ul *ngIf="!_booleanMenuMaximize" class="UlMenuMinimize ListNavigation">
				<li class="ListNavigationMenu" [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Home) }" (click)="goToHome()">
					<label>Halaman Utama</label>
				</li>
				<li class="ListNavigationMenu" [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.PremiumCalculator) }" (click)="goToPremiumCalculator();">
					<label>Kalkulator Premi</label>
				</li>
				<li class="ListItemNavigation">
					<label for="checkBoxNavigationTriggerTicket">Profil</label>
					<input type="checkbox" class="CheckBoxNavigationTrigger" id="checkBoxNavigationTriggerTicket" [checked]="this._booleanNavigationItem" [(ngModel)]="_booleanNavigationItem">
					<ul class="ListSubNavigation" *ngIf="_modelUserSignIn.FirstName !== null && _modelUserSignIn.FirstName !== undefined && _modelUserSignIn.FirstName !== ''">
						<li class="ListItemSubNavigation" [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Profile) }">
							<label (click)="goToProfile()">Profil</label>
						</li>
						<li class="ListItemSubNavigation">
							<label (click)="callServiceSignOut()">Signout</label>
						</li>
					</ul>
					<ul class="ListSubNavigation" *ngIf="_modelUserSignIn.FirstName === null || _modelUserSignIn.FirstName === undefined || _modelUserSignIn.FirstName === ''">
						<li class="ListItemSubNavigation">
							<label (click)="goToSignIn()">Masuk</label>
						</li>
					</ul>
				</li>
				<li class="ListNavigationMenu" [ngClass]="{'ListNavigationMenuActive' : setActiveNavigation(this._enumNavigationMenu.Cart) }" (click)="goToNotification();">
					<label>Notifikasi</label>
				</li>
				<li>
					<img id="imageMainDomain" (click)="goToHome()" src="../../assets/logos/sakina/logo_brins_primary.svg" alt="BRI Insurance - Logo BRI Insurance">
				</li>
			</ul>
		</nav>
	</div>
</div>