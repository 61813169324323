//#region IMPORT

import { ENUM_DOCUMENT_TYPE, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class SupportingDocumentModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for customer model, used for link to user model.
		Author: Ibrahim Aziz.
		Created on : Thursday, 1 April 2021. 				Updated on : Thursday, 1 April 2021.
		Created by : Ibrahim Aziz.							Updated by : Ibrahim Aziz.
		Version : 1.0:2.
	*/

	Name?: string;
	Format?: string;
	Extension?: string;
	Size?: number;
	Data?: string;
	DocumentType?: ENUM_DOCUMENT_TYPE;




	/* Attribute - END */

	/* VALIDATION - START */

	validateDocument(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Agent Document");

		if (this.Data == null || this.Data === undefined)
		{
			modelResponse.MessageContent = "Terjadi kesalahan pada daftar attachment.";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	validateDocumentToken(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Pengunduhan Lampiran.");
		const regularExpression: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_ALPHABETNUMERIC);

		if (this.Token == null || this.Token === undefined)
		{
			modelResponse.MessageContent = "Token dokumen kosong! Silahkan hubungi developer!";
		}
		else if (!regularExpression.test(this.Token))
		{
			modelResponse.MessageContent = "Token tidak dalam format yang benar!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	/* VALIDATION - END */


	/* GETTER - START */

	getSupportingDocumentName(): string
	{
		if (this.DocumentType != null && this.DocumentType !== undefined)
		{
			if (this.DocumentType === 0)
			{
				return "KTP";
			}
			else if (this.DocumentType === 1)
			{
				return "NPWP";
			}
			else if (this.DocumentType === 2)
			{
				return "NPWP Perwakilan Badan Usaha";
			}
			else if (this.DocumentType === 3)
			{
				return "Buku Tabungan BRI an. Perwakilan Badan Usaha";
			}
			else if (this.DocumentType === 4)
			{
				return "Sertifikat";
			}
			else if (this.DocumentType === 5)
			{
				return "Akta Notaris";
			}
			else if (this.DocumentType === 6)
			{
				return "Surat Domisili";
			}
			else if (this.DocumentType === 7)
			{
				return "SIUP";
			}
			else if (this.DocumentType === 8)
			{
				return "NIB";
			}
			else if (this.DocumentType === 9)
			{
				return "E PKS Agen";
			}
			else if (this.DocumentType === 10)
			{
				return "E PKS Admin";
			}
			else
			{
				return StringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
	}

	getSupportingDocumentEnum(): any
	{
		if (this.DocumentType != null && this.DocumentType !== undefined)
		{
			if (this.DocumentType === ENUM_DOCUMENT_TYPE.IdentificationNumber)
			{
				return 0;
			}
			else if (this.DocumentType === ENUM_DOCUMENT_TYPE.TaxNumber)
			{
				return 1;
			}
			else if (this.DocumentType === ENUM_DOCUMENT_TYPE.TaxNumberCorporate)
			{
				return 2;
			}
			else if (this.DocumentType === ENUM_DOCUMENT_TYPE.BRISavingsBook)
			{
				return 3;
			}
			else if (this.DocumentType === ENUM_DOCUMENT_TYPE.AgentCertification)
			{
				return 4;
			}
			else if (this.DocumentType === ENUM_DOCUMENT_TYPE.NotarialDeed)
			{
				return 5;
			}
			else if (this.DocumentType === ENUM_DOCUMENT_TYPE.DomicileLetter)
			{
				return 6;
			}
			else if (this.DocumentType === ENUM_DOCUMENT_TYPE.SIUP)
			{
				return 7;
			}
			else if (this.DocumentType === ENUM_DOCUMENT_TYPE.NIB)
			{
				return 8;
			}
			else if (this.DocumentType === ENUM_DOCUMENT_TYPE.EPKSAgentVer)
			{
				return 9;
			}
			else if (this.DocumentType === ENUM_DOCUMENT_TYPE.EPKSAdminVer)
			{
				return 10;
			}
			else
			{
				return StringConstant.STRING_CHARACTER_EMPTY;
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_EMPTY;
		}
	}


	/* GETTER - END */
}

//#endregion