//#region IMPORT

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { QuicklinkStrategy } from "ngx-quicklink";
import { PremiumCalculatorComponent } from "./components/premiumcalculator/premiumcalculator.component";
import { SignupComponent } from "./components/signup/signup.component";
import { HomeComponent } from "./components/home/home.component";
import { LandingComponent } from "./components/landing/landing.component";
import { SigninComponent } from "./components/signin/signin.component";
import { StringConstant } from "./constants/string.constant";
import { FlexibleGuardService } from "./services/guards/flexible.guard.service";
import { PublicGuardService } from "./services/guards/public.guard.service";
import { NotificationComponent } from "./components/notification/notification.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { EditprofileComponent } from "./components/editprofile/editprofile.component";


//#region DECLARATION

const arrayRoute: Routes =
[
	{
		path: "",
		redirectTo: "/home",
		pathMatch: "full"
	},
	{
		path: "signin",
		component: SigninComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "signup",
		component: SignupComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "signin/:" + StringConstant.STRING_ROUTING_KEY_TOKEN,
		component: SigninComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "editprofile",
		component: EditprofileComponent,
		canActivate: [FlexibleGuardService]
	},
	{
		path: "home",
		component: HomeComponent,
		canActivate: [FlexibleGuardService],
		children:
		[
			{
				path: "",
				component: LandingComponent,
			},
			{
				path: "notification",
				component: NotificationComponent,
			},
			{
				path: "profile",
				component: ProfileComponent,
			},
			{
				path: "premium-calculator",
				component: PremiumCalculatorComponent,
			},
		],
	},
];

//#endregion


//#region MODULE

@NgModule
(
	{
		imports:
		[
			RouterModule.forRoot(arrayRoute,
				{
					preloadingStrategy: QuicklinkStrategy,
					scrollPositionRestoration: "top"
				}
			)
		],
		exports: [RouterModule]
	}
)

//#endregion


//#region CLASS

export class AppRoutingModule { }

//#endregion
